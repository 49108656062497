import {APP_VERSION,DEBUG} from "../../Constants";
import Playlist from  "../../playlist/Playlist.Model";

export class DatabaseApiCache {

  static VERSION_KEY = 'version';
  static USER_TOKEN_KEY = 'user_token';
  static USER_PROFILE_KEY = 'user_profile';
  static USER_POSTS_KEY = 'user_posts';
  static USER_FAVPOSTS_KEY = 'user_favposts';
  static POSTS_LAYOUT_KEY = 'playlists_layout';

  static setAppVersion(): void {
    console.info('Set app version',APP_VERSION);
    localStorage.setItem(this.VERSION_KEY, APP_VERSION);
  }

  static getAppVersion(): number | null {
    return parseInt(localStorage.getItem(this.VERSION_KEY));
  }

  static setToken(token: string): void {
    if(!token) return;
    DEBUG && console.log("Store user token",token);
    localStorage.setItem(this.USER_TOKEN_KEY, token);
  }

  static getToken(): string | null {
    return localStorage.getItem(this.USER_TOKEN_KEY);
  }

  static setUser(user: object): void {
    if(!user) return;
    DEBUG && console.log("Store local user",user);
    localStorage.setItem(this.USER_PROFILE_KEY,JSON.stringify(user));
  }

  static getUser(): string | null {
    return JSON.parse(localStorage.getItem(this.USER_PROFILE_KEY)) || undefined;
  }

  static setPosts(posts: array): void {
    if(!posts) return;
    const jspfs = posts.map(function(post){
      return post.toDTO();
    })
    DEBUG && console.log("Store local posts",jspfs);
    localStorage.setItem(this.USER_POSTS_KEY,JSON.stringify(jspfs));
  }

  static getPosts(): string | null {
    const jspfs = JSON.parse(localStorage.getItem(this.USER_POSTS_KEY)) || undefined;
    if (!jspfs) return;
    return jspfs.map(function(jspf){
      return new Playlist(jspf);
    })
  }

  static setPlaylistLayout(layoutName: string): void {
    if(!layoutName) return;
    localStorage.setItem(this.POSTS_LAYOUT_KEY, layoutName);
  }

  static getPlaylistLayout(): string | null {
    return localStorage.getItem(this.POSTS_LAYOUT_KEY);
  }

  static clear(): void {
    console.log("Clear database cache");
    //TOUFIX V3 TOUCHECK this does wipe the whole cache and not only the user keys ?
    localStorage.clear();
  }

}
