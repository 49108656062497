/*
Since we use objects (like JSPF) as props, React considers it should re-render components even if they are equal.
Those functions are used alongisde React.memo to check if props are euals - and if they are, components should not re-render.
CAUTION - Very picky, should be checked/updated when components props are edited.

//https://dmitripavlutin.com/use-react-memo-wisely/
//https://felixgerschau.com/react-performance-react-memo/
//https://gist.github.com/slikts/fd3768de1493419ed9506002b452fcdc
//https://www.npmjs.com/package/react-fast-compare

*/
import {DEBUG} from "../Constants";
import _ from 'lodash';
import shallowEqual from "../utils/react-shallow-compare";

function isEqualComponent(name,prevTrackProps, nextTrackProps) {

  function isEqualProp(key,type,a,b){

    //by type
    switch(type){
      case 'function':
        return a.toString( ) == b.toString( );
      break;
      case 'object':
        return _.isEqual(a,b);
      break;
      default:
        //https://stackoverflow.com/questions/71267889/is-there-a-default-function-i-could-use-for-comparisons-with-react-memo
        return shallowEqual(a,b);
    }
  }

  const props = Object.keys(nextTrackProps);

  //find the first prop that has changed
  const changedProp = props.find(function(prop){
    const a = prevTrackProps[prop];
    const b = nextTrackProps[prop];
    const type = typeof b;
    const bool = !isEqualProp(prop,type,a,b);
    if (bool){
      //DEBUG && console.log("PROP '"+prop+"' OF TYPE '"+type+"' WILL MAKE OUR "+name+" COMPONENT RE-RENDER / OLD -> NEW",a,b);
    }
    return bool
  })

  if (changedProp){
    return false;
  }

  return true;

}

export function isEqualTrackComponent(prevTrackProps, nextTrackProps){
  return isEqualComponent('Track',prevTrackProps, nextTrackProps);
}

export function isEqualTracklistComponent(prevTrackProps, nextTrackProps){
  return isEqualComponent('Tracklist',prevTrackProps, nextTrackProps);
}

export function isEqualPlaylistComponent(prevTrackProps, nextTrackProps) {
  return isEqualComponent('Playlist',prevTrackProps, nextTrackProps);
}

export function isEqualRadioComponent(prevTrackProps, nextTrackProps) {
  return isEqualComponent('Radio',prevTrackProps, nextTrackProps);
}

export function isEqualWizardBoxComponent(prevTrackProps, nextTrackProps) {
  return isEqualComponent('WizardBox',prevTrackProps, nextTrackProps);
}
