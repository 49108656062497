import React from "react";
import { Menu } from 'semantic-ui-react';
import {useNavigate,useParams,matchRoutes,Link} from "react-router-dom";
import { useUser } from "../user/User.Context";
import {getUserLink} from "../utils/Utils";

const UserMenu = (props) => {

  let navigate = useNavigate();
  let {userid,username,page,subpage} = useParams();

  const userContext = useUser();




  const userImage = props.profile?.image ? 'url('+props.profile?.image+')' : '';

  const isMe = (parseInt(userid) === userContext.user.profile?.id);

  const userBaseLink = getUserLink(userid,username);
  const userProfileLink = userBaseLink ?? '#';
  const userFavoritedTracksLink = userBaseLink ? `${userBaseLink}/tracks` : '#';
  const userPlaylistsLink = userBaseLink ? `${userBaseLink}/playlists` : '#';
  const userSettingsLink = userBaseLink ? `${userBaseLink}/settings` : '#';

  return (
    <div id="user-menu">
      <Menu vertical id="left-menu">
        <Menu.Item>
          <span className="menu-title handwritten">{username}</span>
          <Menu.Menu>
            <Link to={userProfileLink}>
            <Menu.Item
              name='Profile'
              active={(page===undefined)}
            />
            </Link>
            <Link to={userFavoritedTracksLink} disabled={!userContext.isLogged}>
              <Menu.Item
                name='Tracks'
                active={( (page==='tracks') && (subpage==='favorited') )}
              />
            </Link>
            <Link to={userPlaylistsLink} disabled={!userContext.isLogged}>
              <Menu.Item
                name='Playlists'
                active={( (page==='playlists') && !subpage )}
              />
            </Link>
            {
              isMe &&
              <Link to={userSettingsLink}>
                <Menu.Item
                  name='Settings'
                  active={(page==='settings')}
                />
              </Link>
            }
          </Menu.Menu>
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default UserMenu;
