//https://blog.microideation.com/2021/11/29/automate-oauth-refresh-token-flow-using-axios-interceptors-in-reactjs-react-native-or-javascript/
//https://blog.liplex.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
//https://gist.github.com/paulsturgess/ebfae1d1ac1779f18487d3dee80d1258

import axios from 'axios';
import { ImportApiCache } from "./cache";
import {DEBUG} from "../../Constants";

// Define defaults
const importAPIclient = axios.create({
  baseURL: `${process.env.REACT_APP_STRAPI_URL}/api`,
  withCredentials:false
  /*
    headers:{
      'content-type': 'application/json'
    },

  */
});

//intercept request before being sent

importAPIclient.interceptors.request.use(
  function(config){

    //TOUFIX V3 TOUCHECK - API key or auth token ?
    //const token = ImportApiCache.getToken();
    const token = process.env.REACT_APP_SPIFF_API_TOKEN;

    if (config.withCredentials){
      if (!token){
        throw new axios.Cancel('Missing bearer token.');
      }
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function(error){
    return Promise.reject(error);
  }
)

//intercept response
importAPIclient.interceptors.response.use(
  function(response) {
    return response;
},async function(error) {

  const config = error.config;
  const token = ImportApiCache.getToken();

  if ( error.response ){ // Request made and server responded

    //Handle API Error message
    if (error.response.data.error){
      let str = `Import API error`;
      if (error.response.data.error.status){
        str+= ` #${error.response.data.error.status}`
      }
      if (error.response.data.error.message){
        str+= ` : ${error.response.data.error.message}`
      }
      throw new Error(str);
    }

  }

  return Promise.reject(error);


});

export {importAPIclient};
