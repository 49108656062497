import React,{useState,useEffect} from "react";
import { toast } from 'react-toastify';
import {
  useLocation,
  useNavigate,
  matchRoutes
} from "react-router-dom";
import {LIVE_TAG_SLUG,DEBUG} from "../../Constants";
import { useUser } from "../../user/User.Context";
import {redirectToPost,getUserLink,getRouteUserId,getRouteUsername,getRoutePlaylistType,isRouteFavoritePosts,getToastErrorMessage} from "../../utils/Utils";
import PlaylistEditModal from "../../playlist/edit/PlaylistEditModal";
import PostQuery from "../../components/PostQuery";

const MusicPosts = (props) => {

  let location = useLocation();
  let navigate = useNavigate();
  const [query,setQuery] = useState();

  //TOUFIX use useParams()
  const routeUsername = getRouteUsername(location);
  const routeUserId = getRouteUserId(location);
  const routePlaylistType = getRoutePlaylistType(location);

  const isFriendsRoute = (routeUsername === 'friends');
  const isCommunityRoute = (routeUsername === 'community');
  const isFavoritesRoute = isRouteFavoritePosts(location);

  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;
  const myUserId = userContext.user.profile?.id;
  const isMe = (parseInt(routeUserId) === myUserId);
  const userBaseLink = getUserLink(myUserId,myUsername);
  const myFollowedUsername = (userContext.user.profile?.usersFollowed || []).map(function(user){
    return user.username;
  })

  //capabilities
  const canCreatePost = userContext.hasCap('createPost');


  //show add new post button on certain pages only
  //TOUFIX URGENT
  const hasNewPostButton = ( canCreatePost && [
    `/music${userBaseLink}/playlists`,
    `${userBaseLink}/playlists`,

  ].includes(location.pathname) );

  const [isNewPlaylist,setIsNewPlaylist] = useState(false);

  const handleNewPlaylistModal = () => {
    setIsNewPlaylist(true);
  }

  //TOUFIX V3 seems there is two functions creating posts, one here and one in Playlist.js
  const handleAddPlaylist = async(playlist) => {

    console.log("POSTS.JSPX ADD PLAYLIST");

    return userContext.createPost(playlist)
    .then(function(newPlaylist){
      const url = newPlaylist.getPostUrl();
      navigate(url);
    })
    .catch(error => {
      toast.error(getToastErrorMessage(error,'Error while creating post'));
    })
  }

  //build playlists query
  useEffect(() => {

    let query = {}

    //TOUFIX V3 TOUMOVE IN MORE GLOBAL FILE ?
    const updateQueryByAuthors = (query,users) => {
      if (!users) return;
      const usersArr = Array.isArray(users) ? users : [users]; // Ensure it is an array
      query.filters = {
        ...query.filters,
        author: {
          $or:usersArr.map(user => ({ username: user.username }))
        }
      }
    }

    //TOUFIX V3 TOUMOVE IN MORE GLOBAL FILE ?
    const updateQueryByFavoriters = (query,users) => {
      if (!users) return;
      const usersArr = Array.isArray(users) ? users : [users]; // Ensure it is an array
      query.filters = {
        ...query.filters,
        favoriters: {
          $or:usersArr.map(user => ({ username: user.username }))
        }
      }
    }

    //TOUFIX V3 TOUMOVE IN MORE GLOBAL FILE ?
    const updateQueryByPlaylistType = (query,type) => {
      switch(type){
        case "static":
        query.filters = {
          ...query.filters,
          synced: false
        }
        break;
        case 'cloud':
          query.filters = {
            ...query.filters,
            synced: true,
            tags:{
              slug:{
                $notIn: [LIVE_TAG_SLUG]
              }
            }
          }
        break;
        case 'live':
          query.filters = {
            ...query.filters,
            synced: true,
            tags:{
              slug:{
                $in: [LIVE_TAG_SLUG]
              }
            }
          }
        break;
      }
    }

    //set playlist type
    updateQueryByPlaylistType(query,routePlaylistType);

    if(isCommunityRoute){

    }else if (isFriendsRoute){
      if (isFavoritesRoute){
        updateQueryByFavoriters(query,userContext.user.profile.usersFollowed);
      }else{
        updateQueryByAuthors(query,userContext.user.profile.usersFollowed);
      }
    }else{
      const routeUser = {username:routeUsername}
      if (isFavoritesRoute){
        updateQueryByFavoriters(query,routeUser);
      }else{
        updateQueryByAuthors(query,routeUser);
      }
    }


    //add pagination
    query.pagination = {
      limit:-1
    }

    setQuery(query);

  }, [location]);

  return (
    <>
      {
        isNewPlaylist &&
        <PlaylistEditModal
        newItem={true}
        open={true}
        onClose={()=>{setIsNewPlaylist(false)}}
        onSubmit={handleAddPlaylist}
        />
      }
      {
        <PostQuery
        query={query}
        onNewItem={hasNewPostButton ? handleNewPlaylistModal : undefined}
        />
      }

    </>
  )
}

export default MusicPosts;
