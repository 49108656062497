import {getUrlDomain} from "../utils/Utils";

export function getImportersByDomain(importers){
  const output = {};

  (importers || []).forEach(function(importer){

    //domain key

    const domain = getUrlDomain(importer.infos.service_url);

    if ( !output.hasOwnProperty(domain) ){
      output[domain] = {
        image:importer.infos.image,
        services:[]
      }
    }

    //service for domain

    const service = {
      name:   importer.infos.name,
      url:    importer.infos.service_url,
      image:  importer.infos.image
    }

    output[domain].services.push(service);

  })

  return output;
}
