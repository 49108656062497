import React, { useState,useEffect } from "react";
import { Input,Dropdown } from 'semantic-ui-react'
import SearchInput from "../../components/SearchInput";
import Playlist from  "../../playlist/Playlist.Model";

export const WizardTabArtist = (props) => {

  const [wizardUrl, setWizardUrl] = useState(props?.playlist?.meta.import_query?.url);
  const [artist, setArtist] = useState('');
  const [type, setType] = useState('lastfm-artist-top-tracks');
  const [album, setAlbum] = useState('');

  const artistOptions = [
    {
      key: 'album',
      text: 'Album',
      value: 'album'
    },
    {
      key: 'lastfm-artist-top-tracks',
      text: 'Top tracks',
      value: 'lastfm-artist-top-tracks'
    },
    {
      key: 'lastfm-similar-artist',
      text: 'Radio',
      value: 'lastfm-similar-artist'
    }
  ];

  //artist input changed
  useEffect(() => {
    const newUrl = buildPlaylistUrl(artist,type,album);
    setWizardUrl(newUrl);
  }, [artist]);

  //type changed
  useEffect(() => {
    const newUrl = buildPlaylistUrl(artist,type,album);
    setWizardUrl(newUrl);
  }, [type]);

  //album input changed
  useEffect(() => {
    const newUrl = buildPlaylistUrl(artist,type,album);
    setWizardUrl(newUrl);
  }, [album]);

  const buildPlaylistUrl = (artist,type,album) => {

    let url = '';

    switch(type){
      case 'lastfm-artist-top-tracks':
        if (artist){
          url = 'artist:' + artist;
        }
      break;
      case 'lastfm-similar-artist':
        if (artist){
          url = 'artist:' + artist + ':similar';
        }
      break;
      case 'album':
        if (artist && album){
          url = 'artist:' + artist + ':album:' + album;
        }
      break;
    }

    return url;

  }

  //send changes to parent
  useEffect(() => {

    //pass to parent
    //(as a playlist rather than an URL so we might add options).
    const playlist = new Playlist();
    playlist.meta.import_query = {
      url:wizardUrl
    }

    props.onChange(playlist);

  }, [wizardUrl]);

  return (
    <div>

      <SearchInput
      delay={500}
      icon='user'
      placeholder='Artist'
      defaultValue={artist}
      submitButton={false}
      onChange={value => setArtist(value)}
      />

      <Dropdown
        placeholder='Options'
        defaultValue={type}
        options={artistOptions}
        onChange={(e, { value }) =>{
          setType(value)
        }}
      />
      {
        (type === 'album') &&

          <SearchInput
          delay={500}
          icon='image outline'
          placeholder='Album'
          defaultValue={album}
          submitButton={false}
          onChange={value => setAlbum(value)}
          />

      }
    </div>
  )
}
