import React from "react";
import { Menu } from 'semantic-ui-react';
import { Link } from "react-router-dom";

import UserTopMenu from "./user/UserTopMenu";
import { useUser } from "../user/User.Context";


import {useLocation} from "react-router-dom";

export const TopMenu = (props) => {

  let location = useLocation();
  const pathname = location.pathname;

  const userContext = useUser();
  const isLogged = userContext.isLogged;
  const myUsername = userContext?.username;


  const getDisplayedUsername = () =>{
    const regex = new RegExp("user/([a-zA-Z0-9_\-]*).*");
    const matches = pathname.match(regex);
    if (!matches) return;
    return matches[1];
  }

  const displayedUsername = getDisplayedUsername();

  return (
      <Menu id="top-menu" pointing secondary fixed="top">
        <Link to="/" id="site-logo">
          <Menu.Item
            name='Spiff Radio'
          />
        </Link>
        <div className="right menu">
          <UserTopMenu/>
        </div>
      </Menu>
  )
}
