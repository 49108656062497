import React from 'react';
import { Button,Icon } from 'semantic-ui-react';
import {useNavigate} from "react-router-dom";
import { useUser } from "../../user/User.Context";
import { useApp } from '../../App.Context';
import {getUserLink} from "../../utils/Utils";


const UserTopMenu = (props) => {

  let navigate = useNavigate();

  const appContext = useApp();
  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;
  const myUserId = userContext.user.profile?.id;

  const userBaseLink = getUserLink(myUserId,myUsername);
  const userProfileLink = userBaseLink ?? '#';

  return(
    <div id="user-menu">
    {
      userContext.isLogged ?
      <>
        <Button
        icon
        primary
        onClick={(e)=>navigate(userProfileLink)}
        >
          <Icon name='user' /> {myUsername}
        </Button>
        <Button
        onClick={(e)=>userContext.userLogout()}
        >
          Logout
        </Button>
      </>
      :
      <>
        <Button
        primary
        onClick={()=>{appContext.setLoginModal('login')}}
        >
        Login
        </Button>
        <Button
        onClick={()=>{appContext.setLoginModal('register')}}
        >
        Register
        </Button>
      </>
    }
    </div>
  )
}

export default UserTopMenu
