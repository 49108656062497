import React from "react";
import { Icon,Label } from 'semantic-ui-react'
import ShortLink from "../../components/ShortLink";
import classNames from "classnames";
import PlayButton from "../../components/PlayButton";

export const TrackSource = (props) => {
  const playlisterSource = props.playlisterSource;

  const handleSourceToggle = (e) => {
    e.preventDefault();

    if (props.current){
      if (typeof props.onTogglePlay === 'function') {
        props.onTogglePlay();
      }
    }else{
      if (typeof props.onSelect === 'function') {
        props.onSelect(playlisterSource.trackIndex,playlisterSource.index);
      }
    }

  }

  return (
    <li
    className={classNames({
      'track-source': true,
      playable:       playlisterSource.playable,
      current:        props.current,
      loading:        props.loading,
      playing:        props.playing,

    })}
    >
      <span className="url-before" onClick={handleSourceToggle}>
        <PlayButton
        size='1em'
        error={!playlisterSource.playable}
        loading={props.loading}
        playing={props.playing}
        circles='false'
        />
      </span>
      <span className="url-main">
        <ShortLink
        url={props.url}
        onClick={handleSourceToggle}
        />
        <span className="url-labels">
          <a href={props.url} target="_blank" rel="noopener noreferrer">
            <Label><Icon name='linkify'/>Open</Label>

          </a>
          {
          playlisterSource &&
          <>
            {
              playlisterSource.provider &&
              <Label color="teal">{playlisterSource.provider.name}</Label>
            }
            {
              !playlisterSource.playable &&
              <Label color="red" title={playlisterSource.error}>not playable</Label>
            }
          </>
        }
        {
          props.current &&
          <Label color="black">selected</Label>
        }
        </span>
      </span>
    </li>
  );
}
