import React from "react";
import {getImportersByDomain} from "./utils";
import { ImporterIcon } from "./ImporterIcon";
import { Loader } from 'semantic-ui-react';
import { useApp } from '../App.Context';


export const ImportersList = (props) => {
  const appContext = useApp();
  const importers = appContext.importers;
  const loading = appContext.loadingImporters;
  const servicesByDomain = getImportersByDomain(importers);

  return (
    <div id="wizard-services">
    {
      loading ?
      <Loader active inline='centered' />
      :
      ((importers || []).length !== 0 ) &&
      <ul>
      {
        Object.keys(servicesByDomain).map((domain,key) => {
          var obj = servicesByDomain[domain];
          return (
            <li key={key}>
              <ImporterIcon
              key={key}
              domain={domain}
              image={obj.image}
              services={obj.services}
              />
            </li>
          )
        })
      }
      </ul>
    }
    </div>

  );
}
