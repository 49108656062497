import React from 'react';
import {getUserLink} from "../utils/Utils";

const UserList = (props) => {

  const users = props.users || [];

  return(
    <ul className="users-list">
    {
      (users || []).map((user,key) => {
        var userImage = user.thumbnail?.url ? `url('${user.thumbnail.url}')` : '';
        var userLink = getUserLink(user.id,user.username);
        //TOUFIX V3 THUMB
        return (
          <li
          key={key}
          title={user.name}
          >
            <a
            href={userLink}
            className="cover-img"
            style={{backgroundImage: userImage}}
            >
            </a>
          </li>
        )
      })
    }
    </ul>
  )

}

export default UserList
