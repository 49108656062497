import React,{useState} from "react";
import { toast } from 'react-toastify';
import UserFriends from "./friends";
import { useUser } from "../../user/User.Context";
import {getToastErrorMessage} from "../../utils/Utils";
import FavoriteButton from "../../components/FavoriteButton";

const UserProfile = (props) => {

  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;

  const profile = props.profile;
  const userImage = profile.thumbnail?.url ? `url('${profile.thumbnail.url}')` : '';
  const isMe = (profile.username === myUsername);

  const favorited = userContext.filterIsFavoriteUser(profile.id);
  const [favoriting,setFavoriting] = useState();

  const handleToggleFollowUser = async() => {

    const bool = !favorited;

    setFavoriting(true);

    return userContext.toggleFavoriteUser(profile.id,bool)
    .then(function(){
      const message = bool ? 'User successfully favorited.' : 'User successfully unfavorited.';
      toast.success(message);
    })
    .catch(error => {
      toast.error(getToastErrorMessage(error,'Error while toggling status'));
    })
    .finally(function(){
      setFavoriting(false);
    })

  }

  return (
    <div id="user-profile">
      <header>
        <div className="user-image cover-img" style={{backgroundImage: userImage}}></div>
        <div className="user-metas">
          <h1>{profile.username}</h1>
        </div>
        <div className="user-actions">
          {
            !isMe &&
            <FavoriteButton
            favorited={favorited}
            onClick={handleToggleFollowUser}
            loading={favoriting}
            disabled={!userContext.isLogged}
            />
          }
        </div>
      </header>
      <UserFriends
      followers={profile.usersFollowing}
      followed={profile.usersFollowed}
      />
    </div>
  )
}

export default UserProfile;
