import React from "react";
import { Menu,Label } from 'semantic-ui-react';
import { useUser } from "../user/User.Context";

import {isRoutePosts,isRouteFavoritePosts,getRoutePlaylistType,getUserLink} from "../utils/Utils";
import { LastLocationPath } from "../utils/LastLocationPath";
import {STAFF_URL} from "../Constants";
import {useNavigate,useLocation,useParams,matchRoutes,Link} from "react-router-dom";

const MainMenu = (props) => {

  let navigate = useNavigate();
  let location = useLocation();
  let {username,userid,page,subpage} = useParams();

  const userContext = useUser();
  const myUsername = userContext.user.profile?.username;
  const myUserId = userContext.user.profile?.id;

  const routePlaylistType = getRoutePlaylistType(location);
  const routeFavorited = isRouteFavoritePosts(location);
  const routePosts = isRoutePosts(location);
  const routeFavoritedPosts = isRouteFavoritePosts(location);

  const lastLocationPath = LastLocationPath.get();
  const playingMenuUrl = lastLocationPath ? lastLocationPath : `music${STAFF_URL}/tracks`;




  const followedUserIds = (userContext.user.profile?.usersFollowed || []).map(function(user){
    return user.id;
  })

  const userBaseLink = getUserLink(myUserId,myUsername);
  const userPlaylistsLink = userBaseLink ? `/music${userBaseLink}/playlists` : '#';
  const userFavoritedTracksLink = userBaseLink ? `/music${userBaseLink}/tracks` : '#';
  const userFriendsPlaylistsLink = ( myUsername && followedUserIds.length ) ? `/music/user/0/friends/playlists` : '#';

  return (
    <Menu vertical id='left-menu'>
    <Menu.Item>
      <Menu.Menu>
        <Link to="/">
          <Menu.Item
            name='About'
            active={( location.pathname === '/' )}
          />
        </Link>
        {
          /*
          TOUFIX IS THIS USEFUL ?J
          <Link to={playingMenuUrl}>
            <Menu.Item
              name='Playing'
              active={location.pathname.startsWith("/playlist/")}
            />
          </Link>
          */
        }
        <Link to={`/music/user/0/community/playlists`}>
          <Menu.Item
            name='All Playlists'
            active={( (username==='community') && routePosts )}
          />
        </Link>
        <Link to="/wizard/url">
          <Menu.Item
            active={location.pathname.startsWith("/wizard")}
            >
            Search / Import
          </Menu.Item>
        </Link>
      </Menu.Menu>
    </Menu.Item>
    <Menu.Item>
      <span className="menu-title handwritten">Staff picks</span>
      <Menu.Menu>
        <Link to={`music${STAFF_URL}/tracks`}>
          <Menu.Item
            name='Tracks'
            active={( (username==='staff') && (page==='tracks') )}
          />
        </Link>
        <Link to={`music${STAFF_URL}/playlists/favorited`}>
          <Menu.Item
            name='Playlists'
            active={( routePosts && (username==='staff') )}
          />
        </Link>
      </Menu.Menu>
    </Menu.Item>
    <Menu.Item  disabled={!userContext.isLogged}>
      <span className="menu-title handwritten">Me</span>
      <Menu.Menu>
        <Link to={userPlaylistsLink} disabled={!userContext.isLogged}>
          <Menu.Item
            name='Playlists'
            active={( (username===myUsername) && routePosts && !routeFavoritedPosts )}
          />
        </Link>
        <Link to={userFavoritedTracksLink} disabled={!userContext.isLogged}>
          <Menu.Item
            name='Tracks'
            active={( (username===myUsername) && (page==='tracks') )}
          />
        </Link>
      </Menu.Menu>
    </Menu.Item>
    <Menu.Item disabled={!userContext.isLogged}>
      <span className="menu-title handwritten">Friends</span>
      {
        userContext.isLogged &&
        <Label>{followedUserIds.length}</Label>
      }
      <Menu.Menu>
        <Link to={userFriendsPlaylistsLink} disabled={!followedUserIds.length}>
          <Menu.Item
            name='Playlists'
            active={( (username==='friends') && routePosts && !routeFavoritedPosts )}
          />
        </Link>
      </Menu.Menu>
    </Menu.Item>
  </Menu>
  )
}

export default MainMenu;
