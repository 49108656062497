import React from "react";
import { Icon } from 'semantic-ui-react';
import ShortLink from "../../components/ShortLink";

export const TrackLink = (props) => {

  const handleClick = (e) => {
    e.preventDefault();
    window.open(props.url, "_blank");
  }

  return (
    <li
    className="track-link"
    >
      <span className="url-before">
        <Icon name='linkify'/>
      </span>
      <span className="url-main">
        <ShortLink
        url={props.url}
        onClick={handleClick}
        />
      </span>
    </li>
  );
}
