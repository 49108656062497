import "./Track.scss";
import React, { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Label,Dropdown } from 'semantic-ui-react';
import classNames from "classnames";
import {DEBUG,PLAYER_ENABLED} from "../Constants";
import ImportAPI from "../services/import/api";
import LastFmAPI from "../services/lastfm";
import { useUser } from "../user/User.Context";
import Playlist from  "../playlist/Playlist.Model";
import Track from  "../track/Track.Model";
import {formatMilliseconds,getWizardPlaylistUrl,getToastErrorMessage} from "../utils/Utils";
import {isEqualTrackComponent} from "../utils/EqualComponents";
import PlayButton from "../components/PlayButton";
import FavoriteButton from "../components/FavoriteButton";
import { TrackUrls } from "./urls/TrackUrls";
import { TrackSourcesButton } from "./urls/TrackSourcesButton";

const TrackComponent = (props) => {

  const userContext = useUser();
  const lastfmEnabled = userContext.user.profile?.lastfm_enabled;
  const lastfmScrobbler = userContext.user.profile?.lastfm_scrobbler;
  const lastfmSyncFavorites = userContext.user.profile?.lastfm_favorite;
  const autoSource = props.autoSource ?? true;

  let navigate = useNavigate();

  const track = props.track//Track model;

  const playlisterTrack = props.playlisterTrack;
  const loadCount = useRef(0); //for debug purpose
  const initialJspf = useRef();

  const [isFavoriting,setIsFavoriting] = useState(false);
  const [hasEdits, setHasEdits] = useState(false);
  const [apiSpotifyMatchRequesting, setApiSpotifyMatchRequesting] = useState(false);
  const [apiLinksRequesting,setApiLinksRequesting] = useState(false);
  const [showUrls, setShowUrls] = useState(false);

  const isFavorited = userContext.filterIsFavoriteTrack(track);

  const trackImage = track.image ? 'url('+track.image+')' : ''
  const trackDurationText = track.duration ? formatMilliseconds(track.duration) : null;
  const apiSpotifyMatchRequested = track?.meta['private/spotifyIDRequested'] || false;
  const apiLinksRequested = track?.meta['private/linksRequested'] || false;

  //on init
  useEffect(()=>{
    //keep a track of the initial JSPF data
    const jspf = {...track};
    initialJspf.current = jspf;
    setHasEdits(false);
  },[])

  useEffect(()=>{
    loadCount.current = loadCount.current + 1;
  })

  //autosourcing on track selection
  useEffect(()=>{
    if (!autoSource) return;
    if (props.current && !props.sources.length && !apiLinksRequested && !apiLinksRequesting){
      mapApiLinks()
    }
  },[props.current,autoSource])

  //get a spotify match and fill track with the data found.
  //used because we need a spotify ID to query links.

  const mapSpotifyTrack = async()=>{

    const newTrack = new Track({...track});

    //we already requested a spotify match.
    /*
    if (apiSpotifyMatchRequested){
      if (track.spotifyID){
        return Promise.resolve(track);
      }else{
        return Promise.reject('No Spotify ID found for this track.');
      }
    }
    */

    setApiSpotifyMatchRequesting(true);

    return ImportAPI.getSpotifyTrackMatch(track)
    .then(function(spotifyTrack){

      newTrack.creator = spotifyTrack.creator;
      newTrack.title = spotifyTrack.title;
      newTrack.album = spotifyTrack.album;
      newTrack.duration = spotifyTrack.duration;
      newTrack.image = spotifyTrack.image ? spotifyTrack.image : newTrack.image;
      newTrack.identifier = [...newTrack.identifier,...spotifyTrack.identifier];

      DEBUG && console.log("TRACK MAPPED WITH SPOTIFY DATA",newTrack,spotifyTrack);

      return newTrack;

    })
    .finally(function(){

      setApiSpotifyMatchRequesting(false);

      //Keep a clue that we did the API request (even if the request failed)
      newTrack.meta['private/spotifyIDRequested']=true;

      updateTrack(newTrack.toDTO());


    })
  }

  //TOUFIX V3 improve this skip logic ?
  //try to get links for this track.
  //It will update the track in the store, thus eventually will skip it automatically after that if no links are loaded.
  const mapApiLinks = async()=>{

    const newTrack = new Track({...track});

    setApiLinksRequesting(true);

    return mapSpotifyTrack()
    .then(function(mappedTrackObj){
      return ImportAPI.getLinksForTrack(mappedTrackObj)
      .then(function(apiLinks){

        const urls = apiLinks.map(function(apiLink){
          return apiLink.url;
        });

        newTrack.addUrls(urls,'import_api');

        return newTrack;

      })
    })
    .catch(err => {
      console.error(err);
    })
    .finally(function(){

      //Keep a clue that we did the API request
      newTrack.meta['private/linksRequested'] = true;
      newTrack.meta['private/spotifyIDRequested'] = true;

      updateTrack(newTrack.toDTO());

      setApiLinksRequesting(false);

    })
  }


  const handleFavoriteTrack = async bool => {

    if (bool === undefined) return;

    //DB

    setIsFavoriting(true);

    await userContext.toggleFavoriteTracks(track,bool)
    .then(function(){
      const message = bool ? 'Track successfully favorited.' : 'Track successfully unfavorited.';
      toast.success(message);
    })
    .catch(error => {
      toast.error(getToastErrorMessage(error,'Error toggling favorite track'));
    })

    //Last.fm

    if ( lastfmEnabled && lastfmSyncFavorites ){

      userContext.setLastfmLoading(true);

      await LastFmAPI.toggleFavoriteTrack(track,bool)
      .catch(error => {
        toast.error(getToastErrorMessage(error,'Error toggling favorite track on Last.fm'));
      })

      userContext.setLastfmLoading(false);
    }

    //end
    setIsFavoriting(false);

  }

  const handleUrlsToggle = () => {
    setShowUrls(!showUrls);
  }

  const handleGetSourceList = () => {
    mapApiLinks()
    .then(function(links){
      setShowUrls(true);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const handleSpotifyLink = () => {
    window.open('http://open.spotify.com/track/' + track.spotifyID, "_blank");
  }

  const handleMusicBraineLink = () => {
    window.open('https://musicbrainz.org/recording/' + track.musicbrainzID, "_blank");
  }

  //TOUFIX TOUCHECK there is a similar fn in Player.js - we should have only one ?
  const handleScrobbleTrack = () => {
    //scrobble track to Last.fm
    console.log(userContext.user.profile);
    if ( lastfmScrobbler && lastfmEnabled ){
      userContext.scrobbleTrack(track)
      .catch(function(error){
        console.error(error);
        toast.error(getToastErrorMessage(error,`Error scrobbling ${track.toString()}`));
      })
    }
  }

  const redirectToArtist = () => {
    const artist = track.creator;
    if (!artist) return;

    //create wizard playlist
    const newPlaylist = new Playlist();
    newPlaylist.meta.import_query = {
      url:`artist:${artist}`
    };

    const path = getWizardPlaylistUrl(newPlaylist);
    navigate(path);
  }

  const redirectToAlbum = () => {
    const artist = track.creator;
    const album = track.album;
    if (!artist || !album) return;

    //create wizard playlist
    const newPlaylist = new Playlist();
    newPlaylist.meta.import_query = {
      url:`artist:${artist}:album:${album}`
    };
    const path = getWizardPlaylistUrl(newPlaylist);
    navigate(path);
  }

  const redirectToSimilarTracks = () => {
    const artist = track.creator;
    const title = track.title;
    if (!artist || !title) return;

    //create wizard playlist
    const newPlaylist = new Playlist();
    newPlaylist.meta.import_query = {
      url:`artist:${artist}:track:${title}:similar`
    };
    const path = getWizardPlaylistUrl(newPlaylist);
    navigate(path);
  }

  const updateTrack = (jspf) => {
    props.onUpdate(props.index,jspf);
  }

  const deleteTrack = () => {
    props.onDelete(props.index);
  }

  const updateRandomTitle = () => {
    let r = (Math.random() + 1).toString(36).substring(7);
    console.log("RANDOM STR",props.index,r);
    const jspf = {...track,title:r};
    updateTrack(jspf);
  }

  const handleToggleTrack = (e) => {
    if (props.current){
      if (typeof props.onTogglePlay === 'function') {
        props.onTogglePlay();
      }
    }else{
      if (typeof props.onSelect === 'function') {
        props.onSelect(props.index);
      }
    }
  }

  //get links (that are not sources)
  const getLinks = () => {

    let urls = track.allUrls;

    const sourceLinks = props.sources?.map(source =>{
      return source.url;
    }) || [];

    return urls.filter(x => !sourceLinks.includes(x));

  }

  const trackLinks = getLinks();

  return(
      <div
      className={classNames({
        track:          true,
        playable:       props.playable,
        current:        props.current,
        loading:        props.loading,
        updating:       (apiLinksRequesting || apiSpotifyMatchRequesting),
        playing:        props.playing,
      })}
      itemProp="track"
      itemScope
      itemType="http://schema.org/MusicRecording"
      data-playable-links={playlisterTrack ? playlisterTrack.length : 0 }
      >
        <div className="track-row track-data">
          {track.trackNum && <meta itemProp="position" content={track.trackNum} />}
          {track.album && <meta itemProp="inAlbum" content={track.album} />}
          {track.image && <meta itemProp="image" content={track.image} />}
          {track.duration && <meta itemProp="duration" content={track.duration} />}
          <span className="track-before" onClick={handleToggleTrack}>
            {
              PLAYER_ENABLED &&
              <PlayButton
              size='3em'
              disabled={!props.playable}
              error={!props.playable}
              loading={(props.loading || apiLinksRequesting)}
              playing={props.playing}
              />
            }
            <div className="track-image cover-img" style={{backgroundImage: trackImage}}></div>
          </span>
          <span className="track-main">
            <div className="nowrap" title={track.title}>
            <span itemProp="name">
            {
              DEBUG && <Label onClick={updateRandomTitle} className="debug">{loadCount.current}</Label>
            }
            {
              track.title ? track.title : <em>(No title)</em>
            }
            </span>
            {trackDurationText &&
              <span className="track-duration">{trackDurationText}</span>
            }
            </div>
            <p className="nowrap" itemProp="byArtist" title={track.creator}>
              {
                track.creator ? track.creator : <em>(No artist)</em>
              }
            </p>
            {track.annotation &&
              <p className="nowrap track-annotation">{track.annotation}</p>
            }
            {(track.spotifyID && DEBUG) &&
              <Label horizontal className="debug">{track.spotifyID}</Label>
            }
          </span>
          <span className="track-after">
            <TrackSourcesButton
            requested={apiLinksRequested}
            identifiers={track.identifier}
            links={trackLinks}
            sources={props.sources}
            expanded={showUrls}
            onToggle={handleUrlsToggle}
            onRequest={handleGetSourceList}
            />
            <span className="track-actions">
              <FavoriteButton
              favorited={isFavorited}
              onClick={()=>handleFavoriteTrack(!isFavorited)}
              loading={isFavoriting}
              disabled={!props.canFavorite}
              />
              <span
              className={classNames({
                'track-toggle-favorite':  true,
                freeze:                   !props.canFavorite
              })}
              >
              </span>
              <Dropdown className="icon" button icon='content'>
                <Dropdown.Menu>
                  {
                    !isFavorited ?
                      <Dropdown.Item className="favorite-action" icon='heart' text='Favorite' onClick={()=>handleFavoriteTrack(true)} disabled={!props.canFavorite} />
                    :
                      <Dropdown.Item className="unfavorite-action" icon='heart outline' text='Unfavorite' onClick={()=>handleFavoriteTrack(false)} disabled={!props.canFavorite} />
                  }
                  <Dropdown.Item icon='folder' text='Add to playlist' onClick={(e)=>{props.onTrackQueueModal(props.index)}} disabled={!props.canQueue} />
                  <Dropdown.Item className="edit-action" icon='pencil' text='Edit'  onClick={(e)=>{props.onTrackEditModal(props.index)}}/>
                  <Dropdown.Item icon='delete' text='Delete' onClick={deleteTrack} disabled={!props.canDelete}/>
                  <Dropdown.Divider />
                  <Dropdown.Item icon='share alternate' text='Share' />
                  {
                    (!track.spotifyID && !apiSpotifyMatchRequested) ?
                    //TOUFIX LOADER ICON ?
                    <Dropdown.Item icon='spotify' text='Search on Spotify' onClick={(e)=>{mapSpotifyTrack()}} disabled={(!track.title || !track.creator)}/>
                    :
                    <Dropdown.Item icon='spotify' text='View on Spotify' onClick={handleSpotifyLink} disabled={!track.spotifyID}/>
                  }
                  {
                    //TOUFIX handle like above for Spotify
                    <Dropdown.Item icon='linkify' text='View on MusicBrainz' onClick={handleMusicBraineLink} disabled={!track.musicbrainzID}/>
                  }
                  <Dropdown.Divider />
                  <Dropdown.Item icon='wizard' text='Scrobble on Last.fm' onClick={handleScrobbleTrack} disabled={(!track.creator || !track.title)}/>
                  <Dropdown.Item icon='wizard' text='More by this artist' onClick={redirectToArtist} disabled={!track.creator}/>
                  <Dropdown.Item icon='wizard' text='Show album' onClick={redirectToAlbum} disabled={(!track.creator || !track.album)}/>
                  <Dropdown.Item icon='wizard' text='Similar tracks' onClick={redirectToSimilarTracks} disabled={(!track.creator || !track.title)}/>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </span>
          </div>
          <TrackUrls
          open={showUrls}
          loading={props.loading}//TOUFIX would be better to use context?
          playing={props.playing}//TOUFIX would be better to use context?
          links={trackLinks}
          sources={props.sources}
          onTogglePlay={props.onTogglePlay}
          onSourceSelect={props.onSelect}
          />
      </div>
  )
}

export default React.memo(TrackComponent,isEqualTrackComponent);
