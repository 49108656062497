import React, {useState} from 'react';
import { Button, Modal,Segment,Message,Form,Dimmer,Loader,Icon } from 'semantic-ui-react';
import {DEBUG} from "../Constants";
import {Link} from "react-router-dom";
import { useUser } from "./User.Context";
import { useApp } from '../App.Context';
import ApiErrorMessage from "../components/ApiErrorMessage";
import DatabaseAPI from "../services/database/api";

const LoginModal = (props) => {

  const appContext = useApp();
  const userContext = useUser();
  const [loading,setLoading] = useState(false);
  const [formData,setFormData] = useState({});
  const [error,setError] = useState();

  const handleSetUsername = (event) => {
    setFormData({
      ...formData,
      identifier:event.target.value
    })
  }

  const handleSetPassword = (event) => {
    setFormData({
      ...formData,
      password:event.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    DatabaseAPI.login(formData)
		.then(function(response){
      setError();
			userContext.dispatch({ type: 'LOGIN_SUCCESS', payload: response });
			appContext.setLoginModal();
		})
    .catch(function(err){
      console.log("ERROR YO",err);
      setError(err);
    })
    .finally(function(){
      setLoading(false);
    })
  }

  const ModalMessage = () => {

    return(
      <Message icon>
        <Icon name='music' />
        <Message.Content>
          <Message.Header>Welcome back on Spiff Radio!</Message.Header>
        </Message.Content>
      </Message>
    )
  }

  return(
    <Modal
      as={Form}
      onSubmit={handleSubmit}
      autoComplete="off"
      id="loginModal"
      closeIcon
      onClose={()=>{appContext.setLoginModal()}}
      open={(appContext.loginModal === 'login')}
    >
      <Modal.Header>Login</Modal.Header>
      <Modal.Content>
        {
          loading &&
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        }
        <Modal.Description>
            {
              error ?
              <ApiErrorMessage error={error}/>
              :
              <ModalMessage/>
            }
            <Segment>
                <Form.Field>
                  <label>Username (or email)</label>
                  <input
                  name="username"
                  autoComplete="username"
                  placeholder='Username'
                  onChange={handleSetUsername}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Password</label>
                  <input
                  name="password"
                  autoComplete="password"
                  type='password'
                  placeholder='Password'
                  onChange={handleSetPassword}
                  />
                </Form.Field>
                <Link to="#" onClick={()=>{appContext.setLoginModal('forgottenPassword')}}>Password forgotten</Link>?
            </Segment>
          </Modal.Description>
        </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={props.onCancel}>Cancel</Button>
        <Button primary type="submit">Login</Button>
      </Modal.Actions>
    </Modal>
  )

}

export default LoginModal
