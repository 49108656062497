/*index.jsx*/
import React, {useEffect,useState} from "react";
import _ from 'lodash';
import {
  useNavigate,
  useLocation,
  matchRoutes,
  Link
} from "react-router-dom";
import { Form,Button,Checkbox,Container,Message,Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import {DEBUG} from "../../../Constants";
import { getToastErrorMessage } from "../../../utils/Utils";

import DatabaseAPI from "../../../services/database/api";
import SpotifyAPI from "../../../services/spotify";
import { useUser } from "../../../user/User.Context";
import classNames from "classnames";

const lastfmSessionUrl = `http://www.last.fm/api/auth/?api_key=${process.env.REACT_APP_LASTFM_API_KEY}`;

const SpotifySettingsSection = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const userContext = useUser();

  //data that we can load / update
  const filterProfileData = profile => {
    return _.pick(profile,['spotify_token']);
  }

  const profile = userContext.user.profile;
  const isConnected = profile?.spotify_enabled;

  const data = filterProfileData(profile);
  const [newData,setNewData] = useState(data);

  const [spotifyProfile,setSpotifyProfile] = useState();
  const [isLoading,setIsLoading] = useState(false);

  const handleSetSpotifySyncFavoritesEnabled = (event) => {
    setNewData({
      ...newData,
      spotify_favorite:!newData.spotify_favorite
    })
  }

  //check for callback URL token and get user session key
  useEffect(()=>{

    //check if this is the LastFM API callback
    const getCode = () => {

      //get authorisation code
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get('spotifyCode');
    }

    const code = getCode();

    if (code){
      console.log("Spotify authorisation code",code);
    }


    /*
    if (code){

      const payload = {
        ...newData,
        spotify_token:token
      };

      updateData(payload)
      .then(function(newData){

        console.log("SPOTIFY UPDATED PROFILE",newData);


        //change URL so token is not checked a second time (would return an error from Last.fm )
        const successUrl = props.location.pathname;
        navigate(successUrl, { replace: true });
      })
      .catch((error) => {
        toast.error('We have not been able to get your Last.fm session token.');
        //TOUFIX TODO disconnect last.fm ?
      });
    }
    */
  },[location])

  //make sure user is connected by testing the Last.fm API
  useEffect(()=>{
    if (!isConnected) return;

    setIsLoading(true);
    SpotifyAPI.getMyProfile()
    .then(function(resp){
      setSpotifyProfile(resp);
    })
    .catch(function(error){
      toast.error('Error getting your Spotify profile data.');
    })
    .finally(function(){
      setIsLoading(false);
    })

  },[isConnected])

  const updateData = async(data) => {

    const updatePromise = userContext.updateProfile(data);

    setIsLoading(true);
    return updatePromise
    .then(function(resp){
      return resp;
    })
    .then(function(profile){
      let newData = filterProfileData(profile);
      setNewData(newData);
      toast.success("Spotify settings successfully updated.");
      return newData;
    })
    .catch(function(error){
      toast.error(getToastErrorMessage(error,'Error updating Spotify settings'));
    })
    .finally(function(){
      setIsLoading(false);
    })
  }

  const handleSubmitForm = async() => {
    updateData(newData);
  }

  const AuthButton = () => {

    const handleDisconnect = async(e) => {
      e.preventDefault();
      setSpotifyProfile();
      updateData({spotify_token:null});
    }

    const authUrlCallback = 'https://www.spiff-radio.org/spotify/auth';//TOUFIX HARDCODED
    const authUrl = `https://accounts.spotify.com/authorize?client_id=${process.env.REACT_APP_SPOTIFY_CLIENT_ID}&redirect_uri=${authUrlCallback}&response_type=token`;

    return(
      <>
      {
        !isConnected ?
        <Link to={authUrl} target="_blank" rel="noopener noreferrer"><Button>Connect</Button></Link>
        :
        <Button onClick={handleDisconnect}>Disconnect</Button>
      }
      </>
    )
  }

  const SpotifyMessage = () => {

    return(
      <Message
      className={classNames({
        'loading': isLoading,
        'freeze': isLoading
      })}
      icon
      >
        <Icon name='spotify' />
        {
          spotifyProfile ?
          <Message.Content>
            <Message.Header>Connected to Spotify as <em>{spotifyProfile?.id}</em></Message.Header>
          </Message.Content>
          :
          <Message.Content>
            <Message.Header>Connect your Spotify account !</Message.Header>

            <p>We can scrobble the tracks you listen to on your last.fm account, and much more!</p>
          </Message.Content>
        }
      </Message>
    )
  }

  return (

    <Container id="settings-lastfm">
    <h3>Spotify <AuthButton/></h3>
      <SpotifyMessage/>
      <Form
      onSubmit={handleSubmitForm}
      className={classNames({
        'loading': isLoading,
        'freeze': !isConnected
      })}
      >
        <Form.Field>
          <Checkbox
          toggle
          label="Sync with Spotify's 'Liked Songs' when favoriting or unfavoriting a track"
          defaultChecked={data?.spotify_favorite}
          onChange={handleSetSpotifySyncFavoritesEnabled}
          />
        </Form.Field>
        <Button type='submit'>Save</Button>
      </Form>
    </Container>
  );
};

export default SpotifySettingsSection;
