import React from "react";
import UserList from "../../user/UserList";

const UserFriends = (props) => {

  const followers = props.followers || [];
  const followed = props.followed || [];

  return (
    <>
    {
      (followers.length > 0) &&
      <div className="user-followers">
        <h3>Followers</h3>
        <UserList users={followers}/>
      </div>
    }
    {
      (followed.length > 0) &&
      <div className="user-followed">
        <h3>Following</h3>
        <UserList users={followed}/>
      </div>
    }
    </>
  )
}

export default UserFriends;
