//https://gist.github.com/jimode/c1d2d4c1ab33ba1b7be8be8c50d64555
//https://kentcdodds.com/blog/how-to-use-react-context-effectively
//https://gist.github.com/jimode/c1d2d4c1ab33ba1b7be8be8c50d64555
import React, { useReducer,createContext,useEffect,useState,useCallback } from 'react';
import {DEBUG} from "../Constants";
import DatabaseAPI from "../services/database/api";
import { DatabaseApiCache } from "../services/database/cache";
import LastFmAPI from "../services/lastfm";

const UserContext = createContext();

//'undefined' values allows us to check wheter or not we did an API call.
const blankState = {
	token:undefined,
	profile:undefined,
	posts:undefined,
	loading: false,
	errorMessage: null
};

//try to populate browser data
const localState = {
	...blankState,
	token:		DatabaseApiCache.getToken() || blankState.token,
	profile: 	DatabaseApiCache.getUser() || blankState.profile,
	posts: 		DatabaseApiCache.getPosts() || blankState.posts
};

function userReducer(state, action){
	DEBUG && console.log('USER REDUCER:',action.type);
	switch(action.type) {

		case 'LOGIN_SUCCESS':

			const token = action.payload?.jwt;

			//store in browser too
			DatabaseApiCache.setToken(token);

			return {
				...state,
				token: token
			};

		case 'PROFILE_REQUEST':
			return {
				...state,
				loading: true,
				errorMessage: action.error,
			};
		break;

		case 'USERDATA_SUCCESS':

			//store in browser too
			DatabaseApiCache.setUser(action.payload);

			return {
				...state,
				profile: action.payload,
				loading: false,
			};

		break;

		case 'USERDATA_ERROR':

			//TOUFIX temporary - this should probably be done somewhere else ?
			//since populating the profile failed, remove user token.
			DatabaseApiCache.clear();

			return {
				...state,
				loading: false,
				errorMessage: action.error,
			};
		break;

		case 'USERDATA_UPDATE':
			return {
				...state,
				loading: true
			};
		break;

		case 'USERPOSTS_REQUEST':
			return {
				...state,
				loading: true,
				errorMessage: action.error,
			};
		break;

		case 'USERPOSTS_SUCCESS':

			//store in browser too
			DatabaseApiCache.setPosts(action.payload);

			return {
				...state,
				posts: action.payload,
				loading: false
			};

		break;

		case 'USERPOSTS_ERROR':
			return {
				...state,
				loading: false,
				errorMessage: action.error
			};
		break;

		case 'USER_LOGOUT':
			return blankState;
		break;

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

export function UserProvider({children}){
  const [state, dispatch] = useReducer(userReducer, localState);
	const [favTracks,setFavTracks] = useState();
	const [lastfmLoading,setLastfmLoading] = useState();
	const [isLogged,setIsLogged] = useState(false);

	//find a post by ID in an array of posts
	const getContextPost = postID =>{

		if (!postID) return;

		return state.posts?.find(function(post){
			return parseInt(post.meta.post_id) === parseInt(postID);
		});
	}

	const deleteContextPost = playlist => {
		const postID = playlist.meta.post_id;
		const post = getContextPost(postID);
		if (!post) return;

		const postIndex = state.posts.indexOf(post);
		if (postIndex === -1) return;

		let newPosts = [...state.posts];
		newPosts.splice(postIndex, 1);//remove from array
		dispatch({ type: 'USERPOSTS_SUCCESS', payload: newPosts });

	}

	//update a single post
	const setContextPost = (post) => {

		const postID = parseInt(post.meta.post_id);

		if (!postID){
			throw new Error('setContextPost requires a post that has an ID.');
		}

		let newPosts = [...state.posts];
		const currentPost = getContextPost(postID);

		//update existing context post
		if (currentPost){
			const currentPostIndex = state.posts.indexOf(currentPost);
			newPosts[currentPostIndex] = post;
			DEBUG && console.info(`Updated old post #${postID} in context...`)
		}else{
			//append new post
			newPosts.push(post);
		}

		DEBUG && console.info(`...Post #${postID} updated in context.`)

		dispatch({ type: 'USERPOSTS_SUCCESS', payload: newPosts });

	}

	const createPost =  async playlist => {

		//TOUFIX logic ?
		playlist.creator = state.profile.username

		let newPost = await DatabaseAPI.createPost(playlist)
		.then(resp => resp.data.data)

		// TOUFIX V3 this should not be needed and we should populate data from response.
		//But the response data is a post and not a playlist like the endpoint does.
		const postID = newPost.id;
		newPost =  await DatabaseAPI.getPostByID(postID);

		setContextPost(newPost);
		return newPost;

	}

	const updatePost = async playlist => {
		let newPost = await DatabaseAPI.updatePost(playlist)
		.then(resp => resp.data.data)

		// TOUFIX V3 this should not be needed and we should populate data from response.
		//But the response data is a post and not a playlist like the endpoint does.
		const postID = newPost.id;
		newPost =  await DatabaseAPI.getPostByID(postID);

		setContextPost(newPost);
		return newPost;
	}

	const deletePost = async playlist => {
		const postID = playlist.meta.post_id;
		DatabaseAPI.deletePost(postID)
		.then(function(response){
			deleteContextPost(playlist);
		})
	}

	const updateProfile = async(payload) => {

		const profile = state.profile;
		const user_id = profile.id;
		const isLogged = Boolean(state.token);

		console.log("UPDATING USER PROFILE...",user_id,payload);

		dispatch({ type: 'USERDATA_UPDATE' });

		return DatabaseAPI.updateProfile(user_id,payload)
		.then(function(data){
			//TOUFIX V3 currently, strapi does not return the same data when profile is updated
			//and when we get the profile.  So get the profile now.
			return populateMyProfile();
		})
		.catch(error => {
			dispatch({ type: 'USERDATA_ERROR', error: error });
		})


	}

	const userLogout = () => {
		DatabaseApiCache.clear();
		dispatch({ type: 'USER_LOGOUT' });
	}

	const populateMyProfile = async()=>{

		dispatch({ type: 'PROFILE_REQUEST' });

		return DatabaseAPI.getMyProfile()
		.then(function(data){

			dispatch({ type: 'USERDATA_SUCCESS', payload: data });
			return data;
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: 'USERDATA_ERROR', error: error });
		})
	}

	//validate token (might have expired)
	//and eventually populate profile
	useEffect(() => {
		if( !state.token ) return;
		populateMyProfile();
	}, [state.token])

	useEffect(() => {
		const bool = (state.profile?.id);
		setIsLogged(bool);
	}, [state.profile])

	//populate user posts
	useEffect(() => {
		if (!state.profile) return;
    if (state.posts !== undefined) return;

		const getMyPosts = async() => {
			return DatabaseAPI.getPosts({
				pagination:{
					limit:-1
				},
				filters:{
					author:{
						username:state.profile.username
					}
				}

			})
		}

		dispatch({ type: 'USERPOSTS_REQUEST' });

		getMyPosts()
		.then(function(posts){
			DEBUG && console.info(`Profile posts loaded for user '${state.profile.username}'`);
			dispatch({ type: 'USERPOSTS_SUCCESS', payload: posts });
		})
		.catch(error => {
			console.error(error);
			dispatch({ type: 'USERPOSTS_ERROR', error: error });
		})

	}, [state.profile])

	//populate favTracks
	useEffect(()=>{
		if (!state.posts) return;
		const favTracksID = state.profile?.favoriteTracks;
		if (!favTracksID) return;
		const playlist = getContextPost(favTracksID);
		setFavTracks(playlist);
	}, [state.posts])

	const toggleQueueTracks = async (tracks,playlist,bool) => {

		const postID = parseInt(playlist.meta.post_id);
		const favTracksID = state.profile?.favoriteTracks;

		if (postID === favTracksID){
			toggleFavoriteTracks(tracks,bool);
			return;
		}

		try{
			const response = await DatabaseAPI.toggleQueueTracks(tracks,playlist,bool)

			if (response.status === 204){//no changes
				DEBUG && console.info("204, Nothing to update!");
			}else{
				// TOUFIX V3 this should not be needed and we should populate data from response.
				//But the response data is a post and not a playlist like the endpoint does.
				const post = await DatabaseAPI.getPostByID(postID);
				setContextPost(post);
			}
		}catch(e){
			throw e;
		}

  }

	const toggleFavoriteTracks = async (tracks, bool) => {
	  try {
	    const response = await DatabaseAPI.toggleFavoriteTracks(tracks, bool);

			if (response.status === 204){//no changes
				DEBUG && console.info("204, Nothing to update!");
			}else{
				// update fav tracks playlist
		    // TOUFIX V3 if the playlist was just created, we don't have favTracksID yet.
				// should reload profile ?
		    const favTracksID = state.profile?.favoriteTracks;
				// TOUFIX this should not be needed and we should populate data from response.
				//But toggleFavoriteTrack returns a post and not a playlist like the endpoint does.
		    const post = await DatabaseAPI.getPostByID(favTracksID);
		    setContextPost(post);

			}

	  } catch (e) {
			throw e;
	  }
	};

	const toggleFavoriteUser = async (userId,bool) => {
    return DatabaseAPI.toggleFavoriteUser(userId,bool)
		.then(function(response){
			populateMyProfile();
		})
  }

	const toggleFavoritePost = async (postId,bool) => {
    return DatabaseAPI.toggleFavoritePost(postId,bool)
		.then(function(response){
			populateMyProfile();
		})
  }

	const scrobbleTrack = async(track) => {

		setLastfmLoading(true);
		return LastFmAPI.scrobbleTrack(track)
		.finally(function(){
			setLastfmLoading(false);
		});
  }

	const filterIsFavoritePost = postId => {
		return ( state.profile?.favoritePlaylists || []).includes(postId);
  }

	const filterIsFavoriteUser = userId => {
    return ( state.profile?.usersFollowed || []).find(function(user){
      return (user.id === userId);
    })
  }

	const filterIsFavoriteTrack = useCallback(
		(jspfTrack)=>{
			const myFavTracks = favTracks;
	  	if (!myFavTracks) return false;
	    return myFavTracks.hasTrack(jspfTrack);
		},
		[favTracks]
	)

	const hasCap = (cap,item) => {

		switch(cap){
			case 'editTracks':
				return true;//we can always edit tracks - eg. export a playlist without saving it.
			break;
			case 'updatePost':
				if (!isLogged) return false;
				if (item){
					const author_id = item.meta['post_author']?.id;
				  const isMyPost = ( author_id && ( author_id === state.profile?.id) );
				  return ( ( isMyPost && state.profile?.capabilities.edit_posts) || state.profile?.capabilities.edit_others_posts );
				}else{
					throw new Error('item is required to check the updatePost capability.')
				}
			break;
			case 'queueTracks'://add a track to any playlist
			case 'createPost':
			case 'favoritePosts':
			case 'favoriteTracks':
				return !!isLogged;
			break;
			default:
				throw new Error(`'${cap}' is not a valid capability.`)
		}
	}

	// NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
	const value = {
	  user: state,
	  isLogged,
	  dispatch,
	  filterIsFavoritePost,
	  filterIsFavoriteTrack,
	  filterIsFavoriteUser,
	  favTracks,
	  toggleFavoriteTracks,
	  toggleQueueTracks,
	  toggleFavoriteUser,
	  toggleFavoritePost,
	  createPost,
	  updatePost,
	  deletePost,
	  hasCap,
	  updateProfile,
	  userLogout,
		//TOUFIX MOVE IN APP CONTEXT ?
	  lastfmLoading,
	  setLastfmLoading,
	  scrobbleTrack
	};

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
