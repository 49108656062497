//https://jsonforms.io/docs/uischema/controls/#scope-string

import React, { useState,useEffect } from "react";

import {
  useParams
} from "react-router-dom";

import { Loader,Tab } from 'semantic-ui-react';
import ImportAPI from "../../services/import/api";
import DatabaseAPI from "../../services/database/api";
import ApiErrorMessage from "../../components/ApiErrorMessage";
import StepRequest from "./StepRequest";
import StepTracklist from "./StepTracklist";
import StepData from "./StepData";

const defaultSchema = {
	//"$schema":"https://json-schema.org/draft/2020-12/schema",
	"type":"object",
	"title":"Playlist importer schema",
	"properties":{
		"request":{
			"type":"object",
			"title":"Request",
			"description":"How the request is made.",
			"required":[],
			"properties":{
				"content_type":{
					"type":"string",
					"title":"Content type",
					"description":"If the playlist type is not automaticlaly detected, force to this type.",
					"enum":[
						"json",
						"xspf",
						"xml",
						"html"
					]
				}
			}
		},

		"selectors":{
			"type":"object",
			"title":"Datas selectors",
			"description":"Selectors that will be used to extract our data.",
      "properties":{
        "playlist_tracks":{
  				"type":"object",
  				"title":"Tracks selectors",
  				"description":"Selectors used to get the playlist tracks.",
          "required":['path'],
  				"$ref":"#/$defs/selector"
  			},
  			"playlist_title":{
  				"$ref":"#/$defs/selector",
  				"title":"Playlist title"
  			},
  			"playlist_author":{
  				"$ref":"#/$defs/selector",
  				"title":"Playlist author"
  			},
  			"playlist_image":{
  				"$ref":"#/$defs/selector",
  				"title":"Playlist image"
  			},
  			"playlist_description":{
  				"$ref":"#/$defs/selector",
  				"title":"Playlist description"
  			},
  			"track_artist":{
  				"$ref":"#/$defs/selector",
  				"title":"Track artist"
  			},
  			"track_title":{
  				"$ref":"#/$defs/selector",
  				"title":"Track title"
  			},
  			"track_album":{
  				"$ref":"#/$defs/selector",
  				"title":"Track album"
  			},
  			"track_image":{
  				"$ref":"#/$defs/selector",
  				"title":"Track image"
  			},
  			"track_duration":{
  				"$ref":"#/$defs/selector",
  				"title":"Track duration"
  			},
  			"track_location":{
  				"$ref":"#/$defs/selector",
  				"title":"Track locations"
  			},
  			"track_link":{
  				"$ref":"#/$defs/selector",
  				"title":"Track links"
  			},
  			"track_identifier":{
  				"$ref":"#/$defs/selector",
  				"title":"Track identifier"
  			}
      }
		}
	},
	"$defs":{
		"path_selector":{
			"type":"string",
			"title":"Selector path",
			"default":"",
			"examples":[]
		},
		"attr_selector":{
			"type":"string",
			"title":"HTML attribute",
			"default":"",
			"examples":["src"]
		},
		"regex_selector":{
			"type":"string",
			"title":"Regex pattern",
			"format":"regex"
		},
    "map":{
			"type":"string",
			"title":"Data mapping"
		},
		"selector":{
			"type":"object",
			"description":"Selectors used to extract datas.",
			"properties":{
				"path":{
					"$ref":"#/$defs/path_selector"
				},
				"attr":{
					"$ref":"#/$defs/attr_selector"
				},
				"regex":{
					"$ref":"#/$defs/regex_selector"
				},
				"map":{
					"$ref":"#/$defs/map"
				}
			}
		}
	}
};

const updateSchema = schema => {

  if (!schema) schema = {};

  return {
    ...schema,
    properties:{
      ...schema.properties,
      request:{
        ...schema.properties?.request,
        required:[...schema?.properties?.request?.required || [],'import_url'],
        properties:{
          ...schema.properties?.request?.properties,
          import_url:{
            "type":"string",
            "title":"Playlist URL",
            "description":"URL where we can find the playlist data."
          },
          playlist_url:{
            "type":"string",
            "title":"Home URL",
            "description":"If you want to hide the real playlist URL, you can set another one here.",
            "format":"uri"
          },
        }
      }
    }

  }
}

const AdvancedWizard = (props) => {

  let { post_id } = useParams();

  const [apiError,setApiError] = useState();
  const [playlist,setPlaylist] = useState();

  const [schema,setSchema] = useState(updateSchema(defaultSchema));
  const [params,setParams] = useState();
  const [data, setData] = useState();
  const [errors, setErrors] = useState();
  const [debugData,setDebugData] = useState();
  const [postLoading,setPostLoading] = useState(false);
  const [loading,setLoading] = useState(false);

  //init
  useEffect(() => {
    if (post_id!==undefined){
      //TOUFIX TOUREMOVE force a post
      populatePost(post_id);
    }
  }, [post_id]);

  //get the JSON data for this playlist
  const populatePost = async(post_id) => {
    setApiError();
    setPostLoading(true);
    DatabaseAPI.getPostByID(post_id)
    .then(function(playlist){
      setPlaylist(playlist);
    })
    .catch(function(error){
      setApiError(error);
    })
    .finally(function(){
      setPostLoading(false);
    })
  }

  const handleSubmitStep = (newData,newErrors) => {
    setData(newData);
    setErrors(newErrors)
  }

  //init
  useEffect(() => {
    if (playlist){
      let options = playlist.meta.import_query?.options;//TOUFIX URGENT this MUST be an object!
      setData(options);
    }
  }, [playlist]);

  useEffect(() => {
    if (data !== undefined){
      console.log("DATA UPDATED",data);
    }
  }, [data]);

  useEffect(() => {
    if (errors !== undefined){
      console.log("ERRORS UPDATED",errors);
    }
  }, [errors]);

  useEffect(() => {
    if (data === undefined) return;

    const import_url = playlist.meta.import_query?.url;

    console.log("GET DEBUG",import_url,data);

    setLoading(true);

    ImportAPI.getDebug(import_url,data)
    .then(function(result){
      setDebugData(result);
    })
    .catch(err => {
      setDebugData();
      //TOUFIX+warning ?
    })
    .finally(function(){
      setLoading(false);
    })

  }, [data]);


  useEffect(() => {
    if (!debugData) return;
    console.log("GOT DEBUG",debugData);
    setSchema(updateSchema(debugData?.schema));
    setParams(debugData?.params)
  }, [debugData]);

  const getPanes = () => {
    return [
      { menuItem: 'input', render: () => <Tab.Pane><xmp>{JSON.stringify(data, null, 2) }</xmp></Tab.Pane> },
      { menuItem: 'schema', render: () => <Tab.Pane><xmp>{JSON.stringify(schema, null, 2) }</xmp></Tab.Pane> },
      { menuItem: 'params', render: () => <Tab.Pane><xmp>{JSON.stringify(params, null, 2) }</xmp></Tab.Pane> },
      { menuItem: '1. Request', render: () =>
      <Tab.Pane>
        <StepRequest
        schema={schema}
        data={data}
        errors={errors}
        debugData={debugData}
        onSubmit={handleSubmitStep}
        />
      </Tab.Pane>
      },
      { menuItem: '2. Tracklist', render: () =>
      <Tab.Pane>
        <StepTracklist
        schema={schema}
        data={data}
        errors={errors}
        debugData={debugData}
        onSubmit={handleSubmitStep}
        />
      </Tab.Pane>
      },
      { menuItem: '3. Data', render: () =>
      <Tab.Pane>
        <StepData
        schema={schema}
        data={data}
        errors={errors}
        debugData={debugData}
        onSubmit={handleSubmitStep}
        />
      </Tab.Pane>
      },
      { menuItem: 'Result', render: () =>
      <Tab.Pane>
        RESULT
      </Tab.Pane>
      }
    ]
  }

  return (
    <>
    {
      apiError ?
        <ApiErrorMessage error={apiError}/>
      :
      postLoading ?
      <Loader active inline='centered' />
      :
      <Tab id="advanced-importer" panes={getPanes()} defaultActiveIndex={1} className={loading ? 'disabled' : ''} />
    }
    </>
  );
};

export default AdvancedWizard;
