import React from "react";
import ErrorMessage from "../components/ErrorMessage";

const ApiErrorMessage = (props) => {
  const error = props.error;

  return (
    <ErrorMessage
    title={error.name}
    content={error.message}
    />
  );
};

export default ApiErrorMessage;
