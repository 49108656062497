import React from "react";
import { Label,Button,Icon } from 'semantic-ui-react';

export const TrackSourcesButton = (props) => {

  const handleToggle = (e) => {
    e.preventDefault();
    if (typeof props.onToggle === 'function') {
      props.onToggle();
    }
  }

  const handleRequest = (e) => {
    e.preventDefault();
    if (typeof props.onRequest === 'function') {
      props.onRequest();
    }
  }

  const totalSources = props.sources?.length || 0;
  const totalLinks = props.links?.length || 0;
  const totalUrls = totalSources + totalLinks;

  return (
    <Button as='div' labelPosition='left' className='track-links-handle'>
      {
        (totalUrls > 0) &&
        <>
          {
            (totalSources > 0) &&
            <Label
            basic
            pointing={!props.requested ? 'right' : false}
            onClick={handleToggle}
            >
              <Icon name="play"/>{totalSources}
            </Label>
          }
          {
            (totalLinks > 0) &&
            <Label
            basic
            pointing={!props.requested ? 'right' : false}
            onClick={handleToggle}
            >
              <Icon name="linkify"/>{totalLinks}
            </Label>
          }
        </>
      }
      {!props.requested &&
        <Button
        onClick={handleRequest}
        disabled={props.requested}
        >
          Get links
        </Button>
      }
    </Button>
  );

}
