import React from "react";

import {
  BrowserRouter as Router
} from "react-router-dom";

import { AppProvider } from "./App.Context";
import { UserProvider } from "./user/User.Context";
import AppContent from "./AppContent";


const App = (props) => {

  return (
    <AppProvider>
      <UserProvider>
          <Router>
            <AppContent/>
          </Router>
      </UserProvider>
    </AppProvider>
  );
}

export default App;
