import React, {useState} from 'react';
import { Button, Modal,Segment,Message,Form,Dimmer,Loader,Icon } from 'semantic-ui-react';
import {DEBUG} from "../Constants";
import { useUser } from "./User.Context";
import { useApp } from '../App.Context';
import ApiErrorMessage from "../components/ApiErrorMessage";
import DatabaseAPI from "../services/database/api";

const ForgottenPasswordModal = (props) => {

  const appContext = useApp();
  const userContext = useUser();
  const [loading,setLoading] = useState(false);
  const [formData,setFormData] = useState({});
  const [error,setError] = useState();

  const handleSetEmail = event => {
    setFormData({
      ...formData,
      email:event.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    DatabaseAPI.forgottenPassword(formData)
		.then(function(response){
      setError();
			userContext.dispatch({ type: 'LOGIN_SUCCESS', payload: response });
			appContext.setLoginModal();
		})
    .catch(function(err){
      console.log("ERROR YO",err);
      setError(err);
    })
    .finally(function(){
      setLoading(false);
    })
  }

  return(
    <Modal
      as={Form}
      onSubmit={handleSubmit}
      autoComplete="off"
      id="loginModal"
      closeIcon
      onClose={()=>{appContext.setLoginModal()}}
      open={(appContext.loginModal === 'forgottenPassword')}
    >
      <Modal.Header>Forgotten Password</Modal.Header>
      <Modal.Content>
        {
          loading &&
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        }
        <Modal.Description>
            {
              error &&
              <ApiErrorMessage error={error}/>
            }
            <Segment>
              <Form.Field>
                <label>Email</label>
                <input
                name="email"
                placeholder='Email'
                onChange={handleSetEmail}
                />
              </Form.Field>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={props.onCancel}>Cancel</Button>
        <Button primary type="submit">Submit</Button>
      </Modal.Actions>
    </Modal>
  )

}

export default ForgottenPasswordModal
