/*index.jsx*/
import React from "react";
//Functional Component
const NotFoundPage = () => {
  return (
    <div>
      <h3>404</h3>
      <small>not found.</small>
    </div>
  );
};

export default NotFoundPage;
