import React,{useContext} from "react";
import classNames from "classnames";
import { Icon,Button } from 'semantic-ui-react'
import { ProgressClickable } from "./ProgressClickable";
import { useUser } from "../user/User.Context";
import { PlayerContext } from "../player/Player.Context";

export const PlayerControls = (props) => {

  const userContext = useUser();
  const lastfmEnabled = userContext.user.profile?.lastfm_enabled;
  const scrobblerEnabled = userContext.user.profile?.lastfm_scrobbler;

  const [playerState, playerDispatch] = useContext(PlayerContext);


  const playlister = props.playlister;
  const playlisterControls = props.playlisterControls;
  const playlisterPlaylist = props.playlisterPlaylist;

  //get current track
  const currentTrack = playlisterPlaylist?.getCurrentTrack();

  const isPlaying = playlisterControls?.playing;
  const canPlay = playlisterPlaylist?.tracks.length;
  const canPause = (canPlay && isPlaying);

  const hasPreviousTrack = playlisterControls?.has_previous_track;
  const hasNextTrack = playlisterControls?.has_next_track;
  const hasPreviousSource = playlisterControls?.has_previous_source;
  const hasNextSource = playlisterControls?.has_next_source;

  const handlePrevious = (e) => {
    playlister.current.previousTrack();
  }

  const handleNext = (e) => {
    playlister.current.nextTrack();
  }

  const onScrobblerToggle = (e) => {
    e.preventDefault();
    alert("TOUFIX");
  }

  //scroll to track <li/> item
  //https://www.robinwieruch.de/react-scroll-to-item
  const handleAnchor = (e) => {
    e.preventDefault();
    if (typeof props.onScrollToTrack === 'function') {
      props.onScrollToTrack();
    }
  }

  const handleWidgetBt = (e) => {
    e.preventDefault();
    playerDispatch({
      type: "TOGGLE_WIDGET"
    });
  }

  const handleLoopingBt = (e) => {
    e.preventDefault();
    playerDispatch({
      type: "TOGGLE_LOOPING"
    });
  }

  const handleMuteBt = (e) => {
    e.preventDefault();
    handleMute();
  }


  const handleMute = (bool) => {
    playerDispatch({
      type: "TOGGLE_MUTED",
      payload:bool
    });
  }

  const handleVolumeSet = (e,percent) => {
    e.preventDefault();
    playerDispatch({
      type: "SET_VOLUME",
      payload:percent/100
    });

    //toggle mute
    let muted = (percent === 0);
    handleMute(muted);
  }

  const handleSeekTo = (e,percent) => {
    const float = percent / 100;
    const reactPlayer = playlister.current.getReactPlayer();
    reactPlayer.seekTo(parseFloat(float))
  }

  return (
    <div className="player-controls">
      <ProgressClickable
      percent={playerState.media_progress * 100}
      size="tiny"
      onMouseUp={handleSeekTo}
      disabled={false}//TOUFIX SHOULD BE IF HAS NO SOURCE
      className='player-control-seek'
      />
      <div className="player-controls-buttons">
        {isPlaying &&
          <Button icon circular onClick={props.onTogglePlay} disabled={!canPause}>
            <Icon name='pause' />
          </Button>
        }
        {!isPlaying &&
          <Button icon circular onClick={props.onTogglePlay} disabled={!canPlay}>
            <Icon name='play' />
          </Button>
        }

        <Button icon circular onClick={handlePrevious} disabled={!hasPreviousTrack}>
          <Icon name='backward' />
        </Button>

        <Button icon circular onClick={handleNext} disabled={!hasNextTrack}>
          <Icon name='forward' />
        </Button>

        <div
        className={classNames({
          'player-control-volume': true,
          'muted': playerState.muted
        })}
        >
          <div>
            {playerState.muted &&
              <Button icon circular onClick={handleMuteBt}>
                <Icon name='volume off' />
              </Button>
            }
            {!playerState.muted &&
              <Button icon circular onClick={handleMuteBt}>
                <Icon name='volume up' />
              </Button>
            }
          </div>
          <ProgressClickable
          percent={playerState.volume * 100}
          onMouseUp={handleVolumeSet}
          />
        </div>
        <Button icon circular onClick={handleAnchor} disabled={!currentTrack}>
          <Icon name='map marker alternate' />
        </Button>
        {playerState.widget &&
          <Button icon circular onClick={handleWidgetBt}>
            <Icon name='compress' />
          </Button>
        }
        {!playerState.widget &&
          <Button icon circular onClick={handleWidgetBt}>
            <Icon name='expand' />
          </Button>
        }
        <Button icon circular
        onClick={handleLoopingBt}
        className={classNames({
          'player-control-looping': true,
          'active': playerState.looping
        })}
        >
          <Icon name='redo' />
        </Button>
        {
          <Button icon circular
          onClick={onScrobblerToggle}
          disabled={!lastfmEnabled}
          className={classNames({
            'player-control-scrobbler': true,
            'active': scrobblerEnabled
          })}
          >
            {
              userContext.lastfmLoading ?
              <Icon name='circle notch' />
              :
              <Icon name='lastfm' />
            }
          </Button>
        }
      </div>
    </div>
  )
}
