import {Track as BackendTrack} from 'spiff-utils/build/entities/models';
import {DEBUG} from "../Constants";
import {
  filterIsSinglePropObj,
  filterIdentifierUrls
} from "../utils/Utils";
import {
  filterPlayableUrls
} from "../player/utils";

export default class Track extends BackendTrack{

  constructor(jspf){
    super(jspf);
    //TOUFIX V3 logic should be in Player.js, when populating ReactPlaylister playlist ?
    //we cannot be sure which URLs are playable on track init,
    //because maybe that ReactPlayer can play some URLs that are not set on the location property.
    this.location = filterPlayableUrls( this.allUrls );
  }

  removePrivateMetas(){
    for (const key in this.meta) {
      if (key.startsWith('private/')) {
        delete this.meta[key];
      }
    }
  }

  //add non-sorted URLs to the track; either to identifier, location, or link.
  addUrls(urls,prefix){

    const track = this;

    //filter urls
    let new_location_urls = filterPlayableUrls(urls);
    let new_identifier_urls = filterIdentifierUrls(urls);
    let new_link_urls = urls.filter(url => (!new_location_urls.includes(url) && !new_identifier_urls.includes(url)) );

    //ignore already populated URLs
    new_location_urls = new_location_urls.filter(url => !track.location.includes(url) );
    new_identifier_urls = new_identifier_urls.filter(url => !track.identifier.includes(url) );
    new_link_urls = new_link_urls.filter(url => !track.linkUrls.includes(url) );

    const new_urls = [...new_location_urls,...new_identifier_urls,...new_link_urls];

    //append
    track.location = [...track.location,...new_location_urls];
    track.identifier = [...track.identifier,...new_identifier_urls];

    new_link_urls.forEach(function(url) {
      track.addLink(url,prefix);
    })

  }

}
