import React, { useState,useEffect } from "react";
import {useParams} from "react-router-dom";
import { Loader,Dimmer } from 'semantic-ui-react';
import SinglePost from "../../components/SinglePost";
import { useUser } from "../../user/User.Context";
import DatabaseAPI from "../../services/database/api";
import ApiErrorMessage from "../../components/ApiErrorMessage";
import ErrorMessage from "../../components/ErrorMessage";
import { LastLocationPath } from "../../utils/LastLocationPath";
import {DEBUG,STAFF_TRACKS_RADIO_ID} from "../../Constants";

const UserTracks = (props) => {

  let { username,userid } = useParams();

  const userContext = useUser();
  const [isLoading,setIsLoading] = useState();
  const [postID,setPostID] = useState();
  const [error,setError] = useState();

  //init
  useEffect(() => {

    if (username === 'staff'){ //picks
      setPostID(STAFF_TRACKS_RADIO_ID);
    }else{//user
      const myself = (username === userContext.user.profile?.username);

      if (myself){//me
        setPostID(userContext.user.profile.favoriteTracks);
      }else{
        setIsLoading(true);
        DatabaseAPI.getUserProfile(userid)
        .then(function(profile){
          setPostID(profile.favoriteTracks);
        })
        .catch(function(error){
          console.log("ERROR GETTING FAVORITE TRACKS POST ID",error);
          setError(error);
        })
        .finally(function(){
          setIsLoading(false);
        })
    }

    }
  }, [username]);

  //init
  useEffect(() => {
    if (postID === undefined) return;
    DEBUG && console.log("GOT POST ID",postID);
  }, [postID]);

  const handleUpdate = (playlist,updateCount) => {
    if(updateCount===1){//playlist initialized
      DEBUG && console.log("Tracks initialized successfully!",playlist);
      const postLink = playlist.getPostUrl();
      if (postLink){
        LastLocationPath.set(postLink);
      }
    }
  }

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      {
        error ?
          <ApiErrorMessage error={error}/>
        :
        postID ?
          <SinglePost
          post_id={postID}
          onUpdate={handleUpdate}
          />
        :
        <ErrorMessage
        content={"There is no playlist containing the favorite tracks of this user."}
        />
      }
    </>
  )
}

export default UserTracks;
