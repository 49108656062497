import { default as providers } from 'react-player/lib/players/index.js';

export const APP_VERSION = 127;//when updated, the local data will be cleared
const IS_DEV = (process.env.NODE_ENV !== 'production');
export const DEBUG = IS_DEV;
export const PLAYER_ENABLED = true;

export const LIVE_TAG_SLUG = 'live';
export const LIVE_TAG_NAME = 'live';
export const STAFF_USER_USERNAME = 'staff';
export const STAFF_TRACKS_RADIO_ID = 160;//TOUFIX THIS SHOULD BE RETRIEVED AUTOMATICALLY FROM STAFF'S PROFILE

export const STAFF_URL = '/user/27/staff';

//https://github.com/cookpete/react-player/blob/master/src/players/index.js
export const REACTPLAYER_PROVIDERS = providers;
export const REACTPLAYER_PROVIDER_KEYS = Object.values(providers).map(provider => {return provider.key});

const getDefaultSortedProviderKeys = () => {
  const frontKeys = ['file'].filter(x => REACTPLAYER_PROVIDER_KEYS.includes(x));//the keys we want to put in front (remove the ones that does not exists in the original array)
  const backKeys = REACTPLAYER_PROVIDER_KEYS.filter(x => !frontKeys.includes(x));//
  return frontKeys.concat(backKeys);
}

const getDefaultDisabledProviderKeys = () => {
  return [].filter(x => REACTPLAYER_PROVIDER_KEYS.includes(x));//the keys we want to disable (remove the ones that does not exists in the original array)
}

export const DEFAULT_PROVIDER_SORTED_KEYS = getDefaultSortedProviderKeys();
export const DEFAULT_PROVIDER_DISABLED_KEYS = getDefaultDisabledProviderKeys();
