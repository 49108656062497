//https://github.com/eclipsesource/jsonforms/issues/2043
//https://stackoverflow.com/questions/74358916/json-schema-how-to-add-properties-to-an-item-that-references-a-definition
//https://jsonforms.discourse.group/t/uischema-and-ref-or-including/261/2
//https://jsonforms.io/api/core/globals.html#fromscoped

import React, { useState,useEffect } from "react";
import {
  materialRenderers,
  materialCells
} from '@jsonforms/material-renderers';

import SelectorControlRenderer, { SelectorControlTester } from './custom/SelectorControl';

import { JsonForms } from '@jsonforms/react';
import { Button,Message } from 'semantic-ui-react';

const defaultConfigOptions = {
 showUnfocusedDescription: true,
}

export const getUISelector = (scope,errors) => {

  let elements = [];

  const scopeErrors = (errors || []).filter(error => {
    const dataPath =  scope.substring(1).replace(new RegExp('properties/','gi'), '');
    return (dataPath === error.instancePath)
  })

  elements.push({
    type: 'Control',
    scope: scope + '/properties/path'
  })

  elements.push({
    type: 'Control',
    scope: scope + '/properties/regex'
  })

  elements.push({
    type: 'Control',
    scope: scope + '/properties/map'
  })

  return {
    type: "Control",
    hasErrors:(scopeErrors.length > 0),
    scope: scope,
    elements: elements
  }

}

const FormDebug = props => {

  return(
    <div className="importer-debug">
      {
        props.data &&
        <>
          {props.data}
        </>
      }
      {
        !props.data &&
        <Message negative>
          <Message.Header>No data found.</Message.Header>
        </Message>
      }
    </div>
  )
}

const FormErrors = props => {
  return(
    <>
    {
      (props.errors || []).length > 0 &&
      <Message negative>
        <Message.Header>Well, there are some errors above. Please fix them!</Message.Header>
      </Message>
    }
    </>
  )
}

const AdvancedWizardStep = (props) => {

  const [data, setData] = useState(props.data || {});
  const [errors, setErrors] = useState([]);

  const [draft,setDraft] = useState(false);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    console.log("STEP DATA",data);
  }, [data]);

  useEffect(() => {
    console.log("STEP ERRORS",errors);
  }, [errors]);

  useEffect(() => {
    const differs = ( JSON.stringify(data) !== JSON.stringify(props.data) );
    setDraft(differs);
  }, [props.data,data]);

  const handleSubmit = e => {
    if (typeof props.onSubmit === 'function') {
      props.onSubmit(data,errors);
    }
  }

  return (
    <div id={props.id} className="advanced-importer-step">
      <h2>{props.schema.title}</h2>
      <h3>{props.schema.description}</h3>
      <JsonForms
        schema={props.schema}
        uischema={props.uischema}
        config={defaultConfigOptions}
        data={data}
        renderers={[
          ...materialRenderers,
          { tester: SelectorControlTester, renderer: SelectorControlRenderer }
        ]}
        cells={materialCells}
        onChange={({ errors, data }) => {
          setData(data);
          setErrors(errors);
          if (typeof props.onChange === 'function') {
            props.onChange({ errors, data });
          }
        }}
      />
      <FormErrors errors={errors}/>
      <Button onClick={handleSubmit} disabled={!draft}>Update</Button>
      {
        ( (errors.length === 0) && !draft) &&
        <FormDebug
        data={props.debugData}
        />
      }

      <xmp>{JSON.stringify(data, null, 2) }</xmp>
    </div>
  );
};

export default AdvancedWizardStep;
