import React from "react"
import { Link } from "react-router-dom"
import { Button } from 'semantic-ui-react'
import {STAFF_URL} from "../Constants";

const HomePage = () => {
  return (
    <div id="homepage">
      <h3>Welcome to Spiff Radio !</h3>
      <div>
        <p>
        Spiff Radio is a collection of some of the best radio stations and playlists around the web.
        It’s a great way to discover some new tunes.
        </p>
        <p>
        Start your journey by listening our <Link to={`music${STAFF_URL}/tracks`}><Button>Picks Playlist</Button></Link>; check our <Link to={`music${STAFF_URL}/playlists/live/favorited`}><Button>Favorite Radios</Button></Link>, or load (almost) any playlist URL with the <Link to="/wizard"><Button>Search / Import</Button></Link> tool !
        </p>
        <br/>
        <p>
        PS : Hi, you user !  Wanna give some feedback ?  Please post <a href="https://github.com/spiff-radio/spiff-frontend/issues" target="_blank" rel="noreferrer">here</a> !<br/>
        PS : Hi, you developer !  Wanna help ? Here's our <a href="https://github.com/spiff-radio" target="_blank" rel="noreferrer">Github repos</a>.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
