import React,{useState,useEffect} from "react";
import { Button,Icon } from "semantic-ui-react";

const FavoriteButton = props => {

  const icons = ['heart outline','heart'];
  const [isHover, setIsHover] = useState(false);
  const [icon, setIcon] = useState();

  useEffect(() => {

    let hoverIcons = [...icons];
    hoverIcons = isHover ? hoverIcons.reverse() : hoverIcons;
    setIcon(!props.favorited ? hoverIcons[0] : hoverIcons[1]);

  }, [isHover,props.favorited]);

  return(
    <Button
    className="ui icon button favorite"
    title={props.favorited ? 'Unfavorite' : 'Favorite'}
    disabled={(props.disabled || props.loading)}
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
    >
    {
      props.loading ?
      <Icon loading name="circle notch"/>
      :
      <Icon
      name={icon}
      onClick={()=>props.onClick(!props.favorited)}
      />
    }
    </Button>
  )
}

export default FavoriteButton;
