/*index.jsx*/
import React, {useEffect,useState} from "react";
import { Button,Checkbox,Form,Icon,Message,Container } from 'semantic-ui-react';
import classNames from "classnames";
import { toast } from 'react-toastify';
import {DEFAULT_PROVIDER_SORTED_KEYS,DEFAULT_PROVIDER_DISABLED_KEYS} from "../../../Constants";
import { getSortedProviders,filterSortedProviderKeys,filterDisabledProviderKeys,getToastErrorMessage } from "../../../utils/Utils";
import { useUser } from "../../../user/User.Context";

const SingleProvider = (props) => {

  const handleMoveUp = e => {
    e.preventDefault();
    if (typeof props.onMove === 'function') {
      props.onMove(props.index,props.index-1);
    }
  }

  const handleMoveDown = e => {
    e.preventDefault();
    if (typeof props.onMove === 'function') {
      props.onMove(props.index,props.index+1);
    }
  }

  const handleToggle = e => {
    e.preventDefault();
    if (typeof props.onToggle === 'function') {
      props.onToggle(props.index);
    }
  }

  return (
    <Form.Field>
      <Checkbox
      toggle
      label={props.name}
      onChange={handleToggle}
      checked={!props.disabled}
      />
      <span className="move-provider">
        <Icon
        name='chevron up'
        className={classNames({
          clickable:true,
          freeze:props.isFirst
        })}
        onClick={handleMoveUp} />
        <Icon
        name='chevron down'
        className={classNames({
          clickable:true,
          freeze:props.isLast
        })}
        onClick={handleMoveDown} />
      </span>
    </Form.Field>
  )
}

const ProvidersSettingsSection = (props) => {

  const userContext = useUser();
  const userProfile = userContext.user.profile;

  const [data,setData] = useState(userProfile?.providers);

  const sortKeys = filterSortedProviderKeys(data?.order);
  const disabledKeys = filterDisabledProviderKeys(data?.disabled);

  const [updateCount,setUpdateCount] = useState(0);

  const [isDefaults,setIsDefaults] = useState(undefined);
  const [isLoading,setIsLoading] = useState(false);


  //check if the provider settings are the default ones
  useEffect(() => {

    const isDefaultSortKeys = (JSON.stringify(sortKeys) === JSON.stringify(DEFAULT_PROVIDER_SORTED_KEYS));
    const isDefaultDisabledKeys = (JSON.stringify(disabledKeys) === JSON.stringify(DEFAULT_PROVIDER_DISABLED_KEYS));
    const bool = (isDefaultSortKeys && isDefaultDisabledKeys);

    setIsDefaults(bool);

  }, [data]);

  const handleToggleProvider = index => {

    let newDisabledKeys = [...disabledKeys];
    const key = sortKeys[index];
    const isDisabled = disabledKeys.includes(key);

    if (!isDisabled){ //add
      newDisabledKeys.push(key);
    }else{ //remove
      index = newDisabledKeys.indexOf(key);
      if (index !== -1) {
        newDisabledKeys.splice(index, 1);
      }
    }

    setData({
      ...data,
      disabled:newDisabledKeys
    })

    setUpdateCount(updateCount+1);//TOUFIX TOUCHECK USED?
  }

  const handleMoveProvider = (oldIndex,newIndex) => {

    if (newIndex >= sortKeys.length) return;

    let newSortKeys = [...sortKeys];

    //move in array
    newSortKeys.splice(newIndex, 0, newSortKeys.splice(oldIndex, 1)[0]);

    setData({
      ...data,
      order:newSortKeys
    })

    setUpdateCount(updateCount+1);//TOUFIX TOUCHECK USED?
  }

  const handleReset = (e) => {
    e.preventDefault();

    setData({
      ...data,
      order:DEFAULT_PROVIDER_SORTED_KEYS,
      disabled:DEFAULT_PROVIDER_DISABLED_KEYS
    })

  }

  const handleSubmit = (event) => {

    event.preventDefault();
    setIsLoading(true);
    userContext.updateProfile({providers:data})
    .then(function(data){
      toast.success("Providers settings successfully updated.");
    })
    .catch(function(error){
      toast.error(getToastErrorMessage(error,'Error updating providers settings'));
    })
    .finally(function(){
      setIsLoading(false);
    })
  }

  return (

    <Container id="settings-providers" className={props.className}>
      <Form
      onSubmit={handleSubmit}
      className={classNames({
        'loading': isLoading
      })}
      >
        <h3>Media Providers</h3>
        <Message icon>

          <Icon name='info' />
            <Message.Content>
              <p>
              You can reorder and disable media providers.
              </p>
            </Message.Content>
        </Message>
          <ul id="providers-list" key={updateCount}>
          {

            getSortedProviders(sortKeys).map((provider,key) => {

              const isFirst = (key===0);
              const isLast = (key===(sortKeys.length-1));
              const isDisabled = disabledKeys.includes(provider.key);

              return (
                <li
                key={key}
                >
                <SingleProvider
                  name={provider.name}
                  index={key}
                  isFirst={isFirst}
                  isLast={isLast}
                  onMove={handleMoveProvider}
                  onToggle={handleToggleProvider}
                  disabled={isDisabled}
                />
                </li>
              )
            })
          }
          </ul>
        <Button type='submit'>Save</Button>
        <Button
        type="button"
        onClick={handleReset}
        disabled={isDefaults}
        >Reset</Button>
      </Form>
    </Container>

  );
};

export default ProvidersSettingsSection;
