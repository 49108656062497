import {
  matchRoutes
} from "react-router-dom";

import _ from 'lodash';

import {
  REACTPLAYER_PROVIDERS,
  DEFAULT_PROVIDER_SORTED_KEYS
} from "../Constants";
import Playlist from "../playlist/Playlist.Model";
import Track from "../track/Track.Model";

import {filterSpotifyTrackUrl} from "spiff-utils/build/clients";
import {filterMusicbrainzTrackUrl} from "spiff-utils/build/clients";


//filter track identifiers URLs
export function filterIdentifierUrls(urls){

  let output = [];

  const spotifyUrls = urls.filter(filterSpotifyTrackUrl);
  const musicbrainzUrls = urls.filter(filterMusicbrainzTrackUrl);

  return output.concat(spotifyUrls,musicbrainzUrls);

}

export function formatMilliseconds(millis){
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export function getUrlDomain(url){
  if(!url) return;

  var domain;
  //find & remove protocol (http, ftp, etc.) and get domain

  if (url.indexOf("://") > -1) {
    domain = url.split('/')[2];
  }
  else {
    domain = url.split('/')[0];
  }

  //find & remove www
  if (domain.indexOf("www.") > -1) {
    domain = domain.split('www.')[1];
  }

  domain = domain.split(':')[0]; //find & remove port number
  domain = domain.split('?')[0]; //find & remove url params

  return domain;
}

//https://stackoverflow.com/a/10903003/782013
export function shortUrl(url, l){
  if(!url) return;
  l = typeof(l) != "undefined" ? l : 50;
  var chunk_l = (l/2);
  url = url.replace("http://","").replace("https://","");

  if(url.length <= l){ return url; }

  var start_chunk = shortString(url, chunk_l, false);
  var end_chunk = shortString(url, chunk_l, true);
  return start_chunk + "..." + end_chunk;
}

function shortString(s, l, reverse){
    var stop_chars = [' ','/', '&'];
    var acceptable_shortness = l * 0.80; // When to start looking for stop characters
    reverse = typeof(reverse) != "undefined" ? reverse : false;
    s = reverse ? s.split("").reverse().join("") : s;
    var short_s = "";

    for(var i=0; i < l-1; i++){
        short_s += s[i];
        if(i >= acceptable_shortness && stop_chars.indexOf(s[i]) >= 0){
            break;
        }
    }
    if(reverse){ return short_s.split("").reverse().join(""); }
    return short_s;
}

/*JSPF have a lot of props that are an array of objects
Those objects have a single prop; like for metas:

metas = [
  {'post_id':123},
  {'post_name':'foo-bar'}
]

This is a method to get a property by key easily.

*/

export function getReactPlayerProviderByKey(needle){
  const providers = REACTPLAYER_PROVIDERS;
  return providers.find( ({ key }) => key === needle );
}

//returns a list of providers keys, sorted based on an input array;
//+ removes unexisting (bad) keys
export function filterDisabledProviderKeys(keys){
  const defaultKeys = DEFAULT_PROVIDER_SORTED_KEYS;
  keys = keys ?? [];

  //remove keys that do not exists in the React Player providers
  return keys.filter(function(key){
    return defaultKeys.includes(key);
  });

}

//returns a list of providers keys, sorted based on an input array;
//+ removes unexisting (bad) keys and appending the missing ones at the end.
export function filterSortedProviderKeys(keys){
  const defaultKeys = DEFAULT_PROVIDER_SORTED_KEYS;

  keys = keys ?? [];

  //remove keys that do not exists in the React Player providers
  keys = keys.filter(function(key){
    return defaultKeys.includes(key);
  });

  //concat custom order + remaining keys
  return keys.concat(defaultKeys.filter(x => !keys.includes(x)));

}

//get providers sorted by input keys
export function getSortedProviders(keys){
  keys = filterSortedProviderKeys(keys);
  return keys.map(key => {
    return getReactPlayerProviderByKey(key);
  });
}

//check if an object has only one single property (like in JSPF meta)
export function filterIsSinglePropObj(item){
  //check is an object
  const isObj = ( typeof item === 'object' && !Array.isArray(item) && item !== null);
  if (!isObj) return false;
  //check has only one prop
  var values = Object.values(item);
  var propCount = values.length;
  if (propCount > 1) return false;

  return true;
}

//https://attacomsian.com/blog/javascript-base64-encode-decode
function encodeUnicodeString(str){
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}

function decodeUnicodeString(encoded){
  //https://attacomsian.com/blog/javascript-base64-encode-decode
  // Going backwards: from bytestream, to percent-encoding, to original string.
  //TOUFIX handle errors if string is malformed
  try{
    return decodeURIComponent(atob(encoded).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }catch (error) {
    throw new Error("error decoding string.");
  }
}

//encodes a JSPF to a string that can be used in an URL (eg. for the wizard)
function encodeObject(obj){
  const str = JSON.stringify(obj);
  return encodeUnicodeString(str);
}

function decodeObject(encoded){
  try{
    return JSON.parse(decodeUnicodeString(encoded));
  }catch (error) {
    throw new Error("error decoding object.");
  }
}

export function decodePlaylist(encoded){
  if (!encoded) return;
  try{
    let item = decodeObject(encoded);
    return Object.assign(
      new Playlist(),
      item
    )
  }catch (error) {
    throw new Error("error decoding playlist.");
  }
}

//encode playlist for the wizard URL
export function getWizardPlaylistUrl(playlist){
  if (!playlist) return;
  const reduced = playlist.toDTO();
  return '/wizard/load/' + encodeObject(reduced);
}

export const getRouteUserId = (location) => {

  const routesMatch = matchRoutes([
    {path:'/music/user/:userid/:username/*'},
    {path:'/user/:userid/:username/*'}
  ], location);

  const match = routesMatch ? routesMatch[0] : undefined;
  if(!match) return;

  return match?.params?.userid;

}

export const getRouteUsername = (location) => {

  const routesMatch = matchRoutes([
    {path:'/music/user/:userid/:username/*'},
    {path:'/user/:userid/:username/*'}
  ], location);

  const match = routesMatch ? routesMatch[0] : undefined;
  if(!match) return;

  return match?.params?.username;

}

export const isRoutePosts = (location) => {

  const routesMatch = matchRoutes([
    {path:'/music/user/:userid/:username/playlists/*'},
    {path:'/user/:userid/:username/playlists/*'}
  ], location);

  const match = routesMatch ? routesMatch[0] : undefined;
  if(!match) return;

  return match ? true : false;

}

export const isRouteFavoritePosts = (location) => {

  const routesMatch = matchRoutes([
    {path:'/music/user/:userid/:username/playlists/favorited/*'},
    {path:'/music/user/:userid/:username/playlists/:playlist_type/favorited/*'},
    {path:'/user/:userid/:username/playlists/favorited/*'},
    {path:'/user/:userid/:username/playlists/:playlist_type/favorited/*'},
  ], location);

  let match = routesMatch ? routesMatch[0] : undefined;

  return match ? true : false;

}

export const getRoutePlaylistType = (location) => {
  const routesMatch = matchRoutes([
    {path:'/music/user/:userid/:username/playlists/:playlist_type/*'},
    {path:'/user/:userid/:username/playlists/:playlist_type/*'},
  ], location);

  const match = routesMatch ? routesMatch[0] : undefined;
  if(!match) return;

  let type = match?.params?.playlist_type;
  return (type === 'favorited') ? undefined : type;

}

export const getToastErrorMessage = (error,title) => {
  return error.message ? `${title}: ${error.message}` : `${title}.`;
}

export function isEqualPlaylists(a,b){
  a = Object.assign(new Playlist(),a);
  b = Object.assign(new Playlist(),b);
  return ( a.hash === b.hash );
}

export function isEqualTracks(a,b){
  a = Object.assign(new Track(),a);
  b = Object.assign(new Track(),b);
  return ( a.hash === b.hash );
}

//compare two playlist to see if they have different radio settings.
//TOUFIX V3 REMOVE ?
export function isEqualLiveSettings(a,b){
  return _.isEqual(a?.meta?.import_query,b?.meta?.import_query);
}

//format a string for comparisons (eg. user searchs for a playlist)
export function toSearchString(str){
  str = (str || '');//if empty
  str = str.trim();//trim
  str = str.toUpperCase();//capitalize
  str = _.deburr(str);//remove accents
  return str;
}

//filter playlists using a search keyword
export function filterPlaylistsBySearchTerm(items,filter){
  filter = toSearchString(filter);

  if(filter){
    items = items.filter(function(playlistObj){
      const title = toSearchString(playlistObj.title);
      return title.includes(filter);
    });
  }
  return items;
}

//filter tracks using a search keyword
export function filterTracksBySearchTerm(items,filter){
  filter = toSearchString(filter);

  if(filter){
    items = items.filter(function(jspfTrack){

      let match = false;
      const values = [jspfTrack.creator,jspfTrack.title];//values to consider

      for (let value of values) {
        value = toSearchString(value);//clean value
        if ( value.includes(filter) ){
          match = true;
          break;
        }
      }

      return match;
    });
  }
  return items;
}

export function getExportFileName(name,extension){
  const currentDate = new Date();
  const dateStr = currentDate.toISOString();
  let filename = name ? name.concat('--',dateStr) : dateStr;
  filename = _.kebabCase(filename);
  if (extension){
    filename = filename.concat('.',extension);
  }
  return filename;
}

export function getUserLink(userId,username){
  if (!userId) return;
  if (!username) return;
  return `/user/${userId}/${username}`;
}
