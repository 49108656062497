import React, {useState,useEffect} from 'react';
import { Input,Form,Message } from 'semantic-ui-react';

const TrackEditBasic = (props) => {

  const [jspf,setJspf] = useState({...props.track});

  useEffect(() => {
    if (typeof props.onUpdateJspf === 'function') {
      props.onUpdateJspf(jspf);
    }
  },[jspf.creator,jspf.title,jspf.album,jspf.trackNum]);


  return(
    <>
      <Form.Field>
        <label>Title</label>
        <Input
        defaultValue={jspf.title}
        onChange={e => setJspf({ ...jspf,title: e.target.value })}
        label={{ icon: 'asterisk' }}
        labelPosition='right corner'
        />
      </Form.Field>
      <Form.Field>
        <label>Artist</label>
        <Input
        defaultValue={jspf.creator}
        onChange={e => setJspf({ ...jspf,creator: e.target.value })}
        label={{ icon: 'asterisk' }}
        labelPosition='right corner'
        />
      </Form.Field>
      <Form.Field>
        <label>Album</label>
        <Input
        defaultValue={jspf.album}
        onChange={e => setJspf({ ...jspf,album: e.target.value })}
        />
      </Form.Field>
      {
        !props.newItem &&
        <Form.Field>
          <label>Position</label>
          <Input
          type="number"
          min="0"
          defaultValue={jspf.trackNum}
          onChange={e => setJspf({ ...jspf,trackNum: parseInt(e.target.value) })}
          />
        </Form.Field>
      }
      <Message
        icon='warning'
        header='Keep your data clean!'
        content="If you change those values, don't forget to review the track links before clicking Apply!"
      />
    </>
  )
}

export default TrackEditBasic;
