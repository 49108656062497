import React,{useState,useEffect} from "react";
import {DEBUG} from "../../Constants";
import WizardBox from "../../wizard/WizardBox";
import Playlist from  "../../playlist/Playlist.Model";
import { Loader,Dimmer,Container,Message,Icon } from 'semantic-ui-react';
import {decodePlaylist} from "../../utils/Utils";
import { LastLocationPath } from "../../utils/LastLocationPath";
import { useLocation,useParams,useNavigate,matchRoutes } from "react-router-dom";
import Radio from "../../radio/Radio";
import { PlayerProvider } from "../../player/Player.Context";
import { ImportersList } from "../../wizard/ImportersList";

const WizardPage = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  let {encodedPlaylist} = useParams();

  const [playlist,setPlaylist] = useState();
  const [loading,setLoading] = useState();

  //initialize playlist when URL changes
  useEffect(() => {
    const dto = decodePlaylist(encodedPlaylist);
    if (dto){
      const playlist = new Playlist(dto);
      DEBUG && console.log("playlist decoded from URL...",encodedPlaylist,playlist);
      setPlaylist(playlist);
    }else{
      setPlaylist(undefined);
    }
  }, [encodedPlaylist]);

  const handleUpdate = (playlist,updateCount) => {
    if(updateCount===1){//on init
      console.log("WIZARD / IMPORT SUCCESSFULL!",playlist);
      LastLocationPath.set(location.pathname);
    }
  }

  return (
    <>
      <Message icon>
        <Icon name='cloud download' />
          <Message.Content>
          <Message.Header>Import <small>almost</small> anything!</Message.Header>
            <p>
            All kind of services are supported.  You're welcome.
            </p>
            <ImportersList/>
          </Message.Content>
      </Message>
      <Dimmer.Dimmable as={Container} dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <WizardBox playlist={playlist}/>
      </Dimmer.Dimmable>
      {
      playlist &&
        <Dimmer.Dimmable as={Container} dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
          <PlayerProvider>
            <Radio
            playlist={playlist}
            onLoading={bool=>setLoading(bool)}
            onUpdate={handleUpdate}
            />
          </PlayerProvider>
        </Dimmer.Dimmable>
      }
    </>
  );
};

export default WizardPage;
