import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Icon } from 'semantic-ui-react'
import "./PlayButton.scss";
import classNames from "classnames";

const PlayButton = (props) => {
  const showCircles = (props.circles !== undefined) ? props.circle : true;

  const getIcon = () => {
    if (props.error){
      return (<Icon name='exclamation triangle'/>)
    }else{
      if (props.playing){
        return (<Icon name='pause' className="clickable"/>)
      }else{
        return (<Icon name='play' className="clickable"/>)
      }
    }
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    if (typeof props.onClick === 'function') {
      props.onClick(e);
    }
  }

  return (
    <div
      className={classNames({
        'play-button':  true,
        'freeze':       (props.disabled)
      })}
      style={{width: props.size,height: props.size}}
      onClick={handleOnClick}
      >
      <div className="play-button-icons">
      {
        getIcon()
      }
      </div>
      {
        props.loading &&
        <CircularProgress
        className="loading-circle"
        variant="indeterminate"
        className="progress-loading"
        size={props.size}
        />
      }
    </div>
  );
}

export default PlayButton;
