import React, {useState,useEffect} from 'react';
import { Input,Form,TextArea } from 'semantic-ui-react';
import Playlist from  "../Playlist.Model";
import {isEqualPlaylists} from "../../utils/Utils";

const PlaylistEditBasic = (props) => {

  const playlist = props.playlist;
  const [newPlaylist,setNewPlaylist] = useState(props.playlist);

  const tagsStr = (playlist.meta.post_tags || []).map(tag=>tag.title).join(', ');

  useEffect(() => {
    if (isEqualPlaylists(newPlaylist,playlist)) return;

    if (typeof props.onChange === 'function') {
      props.onChange(newPlaylist);
    }

  }, [newPlaylist.title,newPlaylist.annotation]);

  const updateTitle = value => {
    setNewPlaylist(
      Object.assign(
        new Playlist(),
        playlist,
        {
          title:value
        }
      )
    )
  }

  const updateDesc = value => {
    setNewPlaylist(
      Object.assign(
        new Playlist(),
        playlist,
        {
          annotation:value
        }
      )
    )
  }

  const updateTags = value => {
    console.log("UPDATE TAGS",value);
  }

  return(
    <>
      <Form.Field>
        <label>Title</label>
        <Input
        defaultValue={newPlaylist.title}
        onChange={(e)=>updateTitle(e.target.value)}
        label={{ icon: 'asterisk' }}
        labelPosition='right corner'
        />
      </Form.Field>
      <Form.Field>
        <label>Description</label>
        <TextArea
        defaultValue={newPlaylist.annotation}
        onChange={(e)=>updateDesc(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Tags</label>
        <Input
        defaultValue={tagsStr}
        onChange={(e)=>updateTags(e.target.value)}
        />
      </Form.Field>
    </>
  )
}

export default PlaylistEditBasic;
