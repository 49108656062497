import React, { useState,useEffect } from "react";
import {
  Routes,
  Route,
  useParams
} from "react-router-dom";
import { Loader,Grid } from 'semantic-ui-react';
import { useUser } from "../../user/User.Context";
import {DEBUG} from "../../Constants";
import DatabaseAPI from "../../services/database/api";
import UserProfile from "./profile";
import UserTracks from "./UserTracks";
import MusicPosts from "../music/posts";

import ApiErrorMessage from "../../components/ApiErrorMessage";
import ProtectedRoute from "../../components/ProtectedRoute";
import UserSettingsPage from "./settings/index";

export const UserRoutes = (props) => {

  const userContext = useUser();
  let {username,userid} = useParams();

  const isMe = (parseInt(userid) === userContext.user.profile?.id);

  const [profile,setProfile] = useState();

  const [error,setError] = useState();
  const [favTrackCount,setFavTrackCount] = useState();
  const [favPostCount,setFavPostCount] = useState();
  const [postCount,setPostCount] = useState();


  const [isNewPlaylist,setIsNewPlaylist] = useState(false);

  const userImage = profile?.image ? 'url('+profile.image+')' : '';

  const handleFavTracksInit = jspfPost => {
    setFavTrackCount(jspfPost.track.length);
  }

  const handlePostsInit = posts => {
    setPostCount(posts.length);
  }

  const handleFavPostsInit = posts => {
    setFavPostCount(posts.length);
  }

  //init
  useEffect(() => {

    if (isMe){
      setProfile(userContext.user.profile);
    }else{
      DatabaseAPI.getUserProfile(userid)
      .then(function(profile){
        setProfile(profile);
      })
      .catch(function(error){
        setError(error);
      })
    }
  }, [username]);

  //init
  useEffect(() => {
    if (profile === undefined) return;
    DEBUG && console.log("GOT USER PROFILE",profile);
  }, [profile]);

  return(
    error ?
      <ApiErrorMessage error={error}/>
    :
    !profile ?
      <Loader active inline='centered' />
    :
    <Routes>
      <Route
      index
      element={<UserProfile profile={profile}/>}
      />
      <Route element={<ProtectedRoute isAllowed={isMe} />}>
        <Route
        path="settings/*"
        element={<UserSettingsPage profile={profile}/>}
        />
      </Route>
      <Route
      path="tracks/*"
      element={<UserTracks/>}
      />
      <Route
      path=":type/*"
      element={<MusicPosts/>}
      />
    </Routes>
  )

}

export default UserRoutes;
