import React from "react";
import {DEBUG} from "../../Constants";
import Playlist from  "../../playlist/Playlist.Model";
import {databaseAPIclient,DATABASE_API_GET_TOKEN_ENDPOINT,DATABASE_API_VALIDATE_TOKEN_ENDPOINT} from "./connect";
import axios from 'axios';

export default class DatabaseAPI extends React.Component {

  static async register(data){

    const config = {
     method: 'post',
     url: '/auth/local/register',
     withCredentials:false,
     data: data
    }

    return databaseAPIclient.request(config)
    .then(resp => resp.data)
  }

  static async login(data){

    const config = {
     method: 'post',
     url: '/auth/local',
     withCredentials:false,
     data: data
    }

    return databaseAPIclient.request(config)
    .then(resp => resp.data)
  }

  static async forgottenPassword(data){

    const config = {
     method: 'post',
     url: '/auth/forgot-password',
     withCredentials:false,
     data: data
    }

    return databaseAPIclient.request(config)
    .then(resp => resp.data)
  }

  static async getTags(){
    const config = {
     method: 'get',
     url: `/tags`,
    }
    return databaseAPIclient.request(config)
    .then(resp => resp.data)
  }

  static async getPostByID(post_id,params) {

    if (!post_id){
      throw("Missing 'post_id' parameter.");
    }

    const config = {
     method: 'get',
     url: `/playlists/${post_id}`,
     params:params
    }

    return databaseAPIclient.request(config)
    .then(resp => resp.data.data)
    .then(function(post){
      return new Playlist(post.attributes.jspf);
    })
  }

  static async getPosts(args){
    const config = {
     method: 'get',
     url: '/playlists',
     params: args
    }

    return databaseAPIclient.request(config)
    .then(resp => resp.data.data)
    .then(function(items){
      DEBUG && console.info('GET POSTS',config.params);
      DEBUG && console.info('POSTS FOUND',items);
      return items;
    })
    .then(function(posts){
      return posts.map(post => new Playlist(post.attributes.jspf));
    })
    .then(function(response){//DEBUG
      DEBUG && console.info('POSTS TO PLAYLISTS',response);
      return response;
    })

  }

  static async getUserProfile(userId){
  	const config = {
  	 method: 'get',
  	 url: `/users/${userId}`,
     params: {
       populate:'*'
     },
  	}
  	return databaseAPIclient.request(config)
  	.then(resp => resp.data)
  }

  static async getMyProfile(){
  	const config = {
  	 method: 'get',
  	 url: '/users/me',
     params: {
       populate:'*'
     },
     withCredentials:true
  	}

    return await databaseAPIclient.request(config)
    .then(resp => resp.data);
  }

  static async updateProfile(id,payload){
  	const config = {
  		method: 'put',
  		url: `/users/${id}`,
      withCredentials:true,
  		data:	payload
  	}
  	return databaseAPIclient.request(config)
  	.then(resp => resp.data)
    .then(function(data){
			DEBUG && console.log("USER DATA UPDATED",data);
			return data;
		})
  }

  static async createPost(playlist){

    const post = playlist.toPost();
    DEBUG && console.info("Create post",post);

    const config = {
     method: 'post',
     url: `/playlists`,
     withCredentials:true,
     data:{
       data:post
     }
    }
    return databaseAPIclient.request(config)
  }

  static async updatePost(playlist){

    const postID = playlist?.meta.post_id;
    const post = playlist.toPost();
    DEBUG && console.info("Update post",postID,post);

    const config = {
     method: 'put',
     url: `/playlists/${postID}`,
     withCredentials:true,
     data:{
       data:post
     }
    }
    return databaseAPIclient.request(config)
  }

  static async deletePost(postID){
    const config = {
     method: 'delete',
     url: `/playlists/${postID}`,
     withCredentials:true
    }
    return databaseAPIclient.request(config)
  }

  static async toggleQueueTracks(tracks,playlist,bool){

    const postID = playlist?.meta.post_id;

    //tracks can be either an array or a single item
    tracks = Array.isArray(tracks) ? tracks : tracks ? [tracks] : undefined;
    const dtos = (tracks || []).map(track=>track.toDTO());

    if (bool === undefined){
      throw("Missing 'bool' parameter.");
    }

    const config = {
      method:	'put',
      url: 		bool ? `/spiff/playlists/${postID}/tracks/queue` : `/spiff/playlists/${postID}/tracks/dequeue`,
      withCredentials:true,
      data:{
        tracks:dtos
      }
    }

    return databaseAPIclient.request(config)
  }

  static async toggleFavoriteTracks(tracks,bool){

    //tracks can be either an array or a single item
    tracks = Array.isArray(tracks) ? tracks : tracks ? [tracks] : undefined;
    const dtos = (tracks || []).map(track=>track.toDTO());

    const config = {
  		method: 'put',
  		url: bool ? `/spiff/playlists/favorites/tracks/queue` : `/spiff/playlists/favorites/tracks/dequeue`,
      withCredentials:true,
      data:{
        tracks:dtos
      }
  	}
  	return databaseAPIclient.request(config)
  }

  static async toggleFavoriteUser(userId,bool){
    const config = {
  		method: 'put',
  		url: bool ? `/spiff/users/${userId}/favorite` : `/spiff/users/${userId}/unfavorite`,
      withCredentials:true
  	}
  	return databaseAPIclient.request(config)
  }

  static async toggleFavoritePost(postId,bool){
    const config = {
  		method: 'put',
  		url: bool ? `/spiff/playlists/${postId}/favorite` : `/spiff/playlists/${postId}/unfavorite`,
      withCredentials:true
  	}
  	return databaseAPIclient.request(config)
  }

}
