import React, {useState,useEffect} from 'react';
import { Button,Modal,Form } from 'semantic-ui-react';
import classNames from "classnames";

const PlaylistShareModal = (props) => {

  return(
    <Modal
      as={Form}
      id="sharePlaylistModal"
      closeIcon
      onClose={props.onClose}
      open={props.open}
      className={classNames({
        loading:false
      })}
    >

      <Modal.Header>Share Playlist</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          COUCOU
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={props.onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )

}

export default PlaylistShareModal
