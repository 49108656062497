import React, { useState } from "react";
import AdvancedWizardStep,{getUISelector} from "./AdvancedWizardStep";
import {Label} from 'semantic-ui-react';

const StepTracklist = (props) => {

  const [onChangeErrors,setOnChangeErrors] = useState(props.errors);

  const getTrackNodesDebug = debug => {
    if (!debug) return;

    const error = debug?.error;
    const data = debug?.data;

    return(
      <>
        <h4>
        Track Nodes
        {
          data &&
          <Label>{data.length}</Label>
        }
        </h4>
        {
          error &&
          <pre><code>{error}</code></pre>
        }
        {
          data &&
          <ul id="debug-track-nodes">
          {
            data.map((node,i) => {
              return (
                <li key={i}>
                  <pre>
                  <code>
                    {node}
                  </code>
                  </pre>
                </li>
              )
            })
          }
          </ul>
        }
      </>
    )
  }

  const debugData = getTrackNodesDebug(props.debugData?.trackNodes);

  const handleOnChange = ({ errors, data }) => {
    setOnChangeErrors(errors);
  }

  const uischema = {
    type: 'VerticalLayout',
    elements: [
      getUISelector('#/properties/selectors/properties/playlist_tracks',onChangeErrors)
    ]
  };

  console.log("TRACKLIST SCHEMA",props.schema);
  console.log("TRACKLIST UI",uischema);
  console.log("TRACKLIST DATA",props.data);
  console.log("TRACKLIST ONCHANGE ERRORS",onChangeErrors);

  return (
    <AdvancedWizardStep
    id="advanced-importer-playlist"
    schema={props.schema}
    uischema={uischema}
    data={props.data}
    debugData={debugData}
    onChange={handleOnChange}
    onSubmit={props.onSubmit}
    />
  );
};

export default StepTracklist;
