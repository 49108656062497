import ReactPlayer from 'react-player'; //TOUFIX could import just the canPlay fn ?

//return playable urls
export const filterPlayableUrls = (urls) =>{
  var output = [];
  urls = [...new Set(urls)];//remove duplicates

  urls.forEach(function(url){
    if ( ReactPlayer.canPlay(url) ){
      output.push(url);
    }
  })

  return output;

}

//return non-playable urls
export const filterNotPlayableUrls = (urls) =>{
  const playableUrls = filterPlayableUrls(urls);
  return urls.filter(x => !playableUrls.includes(x));
}
