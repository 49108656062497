//https://www.robinwieruch.de/react-router-private-routes/#:~:text=Private%20Routes%20in%20React%20Router,page%2C%20they%20cannot%20access%20it.
import {
  Navigate,
  Outlet
} from "react-router-dom";

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/?authRequired',
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
