"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterMusicbrainzTrackUrl = exports.MUSICBRAINZ_TRACK_URL_PREFIX = exports.MUSICBRAINZ_TRACK_ID_REGEX = exports.filterSpotifyTrackUrl = exports.SPOTIFY_TRACK_ID_REGEX = exports.SPOTIFY_TRACK_URL_PREFIX = void 0;
exports.SPOTIFY_TRACK_URL_PREFIX = 'https://open.spotify.com/track/';
exports.SPOTIFY_TRACK_ID_REGEX = new RegExp("spotify\.com\/track\/(.*)");
function filterSpotifyTrackUrl(url) {
    return url.match(exports.SPOTIFY_TRACK_ID_REGEX);
}
exports.filterSpotifyTrackUrl = filterSpotifyTrackUrl;
exports.MUSICBRAINZ_TRACK_ID_REGEX = new RegExp("musicbrainz\.org\/recording\/(.*)");
exports.MUSICBRAINZ_TRACK_URL_PREFIX = 'https://musicbrainz.org/recording/';
function filterMusicbrainzTrackUrl(url) {
    return url.match(exports.MUSICBRAINZ_TRACK_ID_REGEX);
}
exports.filterMusicbrainzTrackUrl = filterMusicbrainzTrackUrl;
