import React from "react";
import AdvancedWizardStep from "./AdvancedWizardStep";

const StepRequest = (props) => {

  const uischema = {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/request/properties/import_url',
      },

      {
        type: 'Control',
        scope: '#/properties/request/properties/playlist_url',
      },
      {
        type: 'Control',
        scope: '#/properties/request/properties/content_type',
        format: 'radio'
      }
    ]
  };

  const getDocumentDebug = debug => {
    if (!debug) return;

    const type_error = debug?.document_type?.error;
    const type_data = debug?.document_type?.data;

    const doc_error = debug?.document?.error;
    const doc_data = debug?.document?.data;

    return(
      <>
        <div>
          <h4>Content Type</h4>
          {
            type_error &&
            <pre><code>{type_error}</code></pre>
          }
          {
            type_data &&
            <pre><code>{type_data}</code></pre>
          }
        </div>
        <div>
          <h4>Raw Content</h4>
          {
            doc_error &&
            <pre><code>{doc_error}</code></pre>
          }
          {
            doc_data &&
            <pre><code>{doc_data}</code></pre>
          }
        </div>
      </>
    )
  }

  const debugData = getDocumentDebug(props.debugData);

  console.log("REQUEST SCHEMA",props.schema);
  console.log("REQUEST DATA",props.data);
  console.log("REQUEST DEBUG",props.debugData);

  return (
    <AdvancedWizardStep
    id="advanced-importer-request"
    schema={props.schema}
    uischema={uischema}
    data={props.data}
    debugData={debugData}
    onSubmit={props.onSubmit}
    />
  );
};

export default StepRequest;
