import React from "react";

export const ImporterIcon = (props) => {
  const desc = props.services.map(service=>{
    return service.name
  }).join(', ');
  return (
    <div title={desc} className='cover-img' style={{
      backgroundImage: `url(${props.image})`
    }}>
    </div>
  );
}
