import React, {useEffect,useState} from "react";
import SearchInput from "../../components/SearchInput";
import Playlist from  "../../playlist/Playlist.Model";

export const WizardTabUrl = (props) => {
  const [textInput, setTextInput] = useState('');

  //prop updated
  useEffect(() => {
    const url = props.playlist?.meta.import_query?.url;
    console.log("URL UPPDATE",url);
    setTextInput(url);
  }, [props.playlist]);

  const handleClearRequest = () => {
    const playlist = new Playlist();
    props.onChange(playlist);
  };

  const handleChange = value => {

    //pass to parent
    //(as a playlist rather than an URL so we might add options).
    const playlist = new Playlist();
    playlist.meta.import_query = {
      url:value
    }

    props.onChange(playlist);
  };

  return <SearchInput
  fluid
  name="wizard_url"
  placeholder='Playlist URL'
  defaultValue={textInput}
  delay={500}
  onChange={handleChange}
  onClear={handleClearRequest}
  />

}
