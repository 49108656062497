import React, { useState,useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";

import WizardPage from "./wizard";

export const WizardRoutes = (props) => {

  return (
    <Routes>
      <Route
      path="load/:encodedPlaylist"
      element={<WizardPage/>}
      />
      <Route
      path="*"
      element={<WizardPage/>}
      />
    </Routes>
  )
}

export default WizardRoutes;
