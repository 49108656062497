import "./Radio.scss";
import React, { useState,useRef,useEffect } from "react";
import { Icon,Message } from 'semantic-ui-react';
import {DEBUG} from "../Constants";
import { toast } from 'react-toastify';

import {isEqualLiveSettings,getToastErrorMessage,isEqualPlaylists} from "../utils/Utils";
import {isEqualRadioComponent} from "../utils/EqualComponents";
import ImportAPI from "../services/import/api";
import ApiErrorMessage from "../components/ApiErrorMessage";
import RadioPagination from "./RadioPagination";
import Playlist from  "../playlist/Playlist.Model";
import PlaylistComponent from "../playlist/Playlist";

const Radio = (props) => {
  const [playing, setPlaying] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [apiError, setApiError] = useState();
  const [playlist,setPlaylist] = useState(props.playlist);
  const [hasInit,setHasInit] = useState(false);

  const [indices,setIndices] = useState();

  const AwaitingRadioTracklist = props => {
    return (
      <>
      {
        (apiError && !pageLoading) ?
        <ApiErrorMessage error={apiError}/>
        :
        <Message icon>
          <Icon name='circle notch' loading />
          <Message.Content>
            <Message.Header>Loading...</Message.Header>
          </Message.Content>
        </Message>
      }
      </>
    )
  }

  //check that request is different from last query
  useEffect(()=>{
    setPlaylist(props.playlist);
    setApiError();
  },[props.playlist]);

  useEffect(()=>{
    if (!hasInit){
      populateRadioPage();//initialize first page
    }
  },[playlist]);

  //check that request is different from last query
  /*
  useEffect(()=>{
    console.log("ZOUBIYO",playlist)
    const changed = !isEqualLiveSettings(props.playlist,inputPlaylist.current);

    if (changed){

      inputPlaylist.current = props.playlist;
      setApiError();
      populateRadioPage();
    }


  },[props.playlist]);
  */

  //pass loading status to parent
  useEffect(()=>{
    if (typeof props.onLoading === 'function') {
      props.onLoading(pageLoading);
    }
  },[pageLoading])

  /*
  Create a new playlist based on the initial radio data;
  Add the page fetched
  */
  const fillPlaylistWithPage = (playlist,pagePlaylist) => {

    //clone
    let newPlaylist =
    Object.assign(
      new Playlist(),
      playlist
    )

    newPlaylist.addPageTracks(pagePlaylist);
    newPlaylist.title = pagePlaylist.title;
    newPlaylist.annotation = pagePlaylist.annotation;

    return newPlaylist;
  }

  const populateRadioPage = async isNext => {

    const postId = playlist.meta.post_id;
    const wizardQuery = playlist.meta.import_query;
    const nextPageParams  = isNext ? playlist.paginationMeta?.next : undefined;

    if (!postId && !wizardQuery) return;

    setPageLoading( true );
    setApiError();



    //the endpoint is different if we query a post ID or a wizard query
    const pagePromise = postId ? ImportAPI.importPlaylistPage(postId,nextPageParams) : ImportAPI.importWizardPage(wizardQuery,nextPageParams)

    pagePromise
    .then(pagePlaylist=>{
      const newPlaylist = fillPlaylistWithPage(playlist,pagePlaylist);
      setHasInit(true);
      setPlaylist(newPlaylist);
    })
    .catch(error => {
      console.log("LOAD RADIO PAGE ERROR",error);
      toast.error(getToastErrorMessage(error,'Error loading playlist'));
      setPlaying(false);
      setApiError(error);
    })
    .finally(function(){
      setPageLoading(false);
    })


  }

  const handleReload = async() => {
    console.log("RADIO / RELOAD");
    populateRadioPage();
  }

  const handleNext = () => {
    console.log("RADIO / NEXT");
    populateRadioPage(true);
  }

  const handleEnded = () => {

    const paginationMeta = playlist.paginationMeta;

    console.log("RADIO / ENDED");
    setPlaying(false);

    const nextPageParams  = paginationMeta?.next;
    const hasNextPage = ( nextPageParams !== undefined );

    if (hasNextPage){
      const oldTrackCount = playlist.track.length
      const newTrackIndex = oldTrackCount;
      console.log("RADIO / LOAD AND PLAY NEXT PAGE TRACK#",newTrackIndex);

      return populateRadioPage(true)
      .then(function(playlist){
        setIndices(newTrackIndex);
        setPlaying(true);
      })
    }else{
      handleReload()
      .finally(function(){
        setIndices(0);
        setPlaying(true);
      })
    }

  }

  //our two Playlist components have the same 'key', so they are considered as one same element.
  //we could give them a different key, but then the page glitches when  it load.
  //instead of this, pass updateCount-1 to the parent so it matches the regular Playlist component behaviour,
  //where when updateCount is 1, the playlist has initialized.
  const handleUpdate = (playlist,updateCount) => {
    if (typeof props.onUpdate === 'function') {
      props.onUpdate(playlist,updateCount-1);
    }
  }

  return (
    <>
    {
      //radio loaded
      hasInit ?
      <>
        <PlaylistComponent
        playlist={playlist}
        action={props.action}
        indices={indices}
        playing={playing}
        onPlaylistEnded={handleEnded}
        onReload={handleReload}
        onUpdate={handleUpdate}
        />
        <RadioPagination
        disabled={pageLoading}
        playlist={playlist}
        onReload={handleReload}
        onNext={handleNext}
        />
      </>
      :
      //awaiting page
      <PlaylistComponent
      playlist={playlist}
      emptyTracklist={<AwaitingRadioTracklist/>}
      />
    }
    </>
  )
}

//export default React.memo(Radio,isEqualRadioComponent);
export default Radio;
