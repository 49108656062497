import React,{useState} from "react";
import { Icon,Label,Dropdown } from 'semantic-ui-react';
import PlayButton from "../components/PlayButton";

import FavoriteButton from "../components/FavoriteButton";
import PlaylistShareModal from "./PlaylistShareModal";
import {PostMetaStatus,PostMetaTags,PostMetaAuthor,PostMetaFavoriters,PostMetaTrackCount,PostMetaLink,PostMetaRadio} from "./PostMetas";

import classNames from "classnames";

/*
const formatDateUpdated = (dateObj) => {
  var date = dateObj.getFullYear()+'-'+(dateObj.getMonth()+1)+'-'+dateObj.getDate();
  var time = dateObj.getHours() + ":" + dateObj.getMinutes() + ":" + dateObj.getSeconds();
  return date+" "+time;
}
*/

export const SavePostButton = props => {

  return(
    <button
    className={classNames({
      ui:true,
      icon:true,
      button:true,
      flash:!props.disabled
    })}
    onClick={props.onClick}
    disabled={(props.disabled || props.loading)}
    title="Save post"
    >
    {
      props.loading ?
        <Icon loading name="circle notch"/>
      :
        <Icon
        name='save'
        />
    }

    </button>
  )
}

export const PlaylistHeader = (props) => {

  const playlist = props.playlist;

  const playlisterControls = props.playlisterControls;
  const playing = playlisterControls?.playing;
  const loading = playlisterControls?.loading;

  const canReloadPlaylist = playlist.meta.synced;
  const playlistImage = playlist.image ? 'url('+playlist.image+')' : '';

  const post_id =  playlist.meta.post_id;
  const playlistTitle = playlist.title ? playlist.title : <>(No title)</>;

  const handleToggleFavorite = bool => {
    if (typeof props.onToggleFavorite === 'function') {
      props.onToggleFavorite(bool);
    }
  }

  const [shareModal,setShareModal] = useState(false);

  return (
    <>
      {
        shareModal &&
        <PlaylistShareModal
        open={true}
        onClose={()=>setShareModal(false)}
        />
      }
      <header>
        {playlist.image ? <meta itemProp="image" content={playlist.image} /> : null}
        {playlist.title ? <meta itemProp="name" content={playlist.title} /> : null}

        <div className="playlist-image cover-img" style={{backgroundImage: playlistImage}}>

          <PlayButton
          size='5em'
          loading={loading}
          playing={playing}
          onClick={props.onTogglePlay}
          />

        </div>
        <div className="playlist-header-content">
          <h1 className="playlist-title handwritten">
          {
            playlist.meta.synced &&
            <PostMetaRadio playlist={props.playlist}/>
          }
          {playlistTitle}
          </h1>
          <div className="post-metas post-metas-bottom">
            <PostMetaAuthor playlist={props.playlist}/>
            <PostMetaStatus playlist={props.playlist}/>
            <PostMetaTrackCount playlist={props.playlist}/>
            {
              playlist.meta.synced &&
              <PostMetaLink playlist={props.playlist}/>
            }
            <PostMetaTags playlist={props.playlist}/>
            <PostMetaFavoriters playlist={props.playlist}/>
          </div>
          <small>{playlist.annotation}</small>
        </div>
        <span className="tracklist-actions">
          <FavoriteButton
          favorited={props.favorited}
          onClick={props.toggleFavorite}
          loading={props.favoriting}
          disabled={!props.canFavorite}
          />
          {
            props.canSave &&
            <SavePostButton
            disabled={!props.hasEdits}
            onClick={props.onSave}
            loading={props.updating}
            />
          }
          <Dropdown className="icon" button icon='content'>
            <Dropdown.Menu>
              <Dropdown.Item className="edit-action" icon='pencil' text='Edit' onClick={props.onEdit} />
              {!props.favorited &&
                <Dropdown.Item icon='heart outline' text='Favorite' onClick={()=>handleToggleFavorite(true)} />
              }
              {props.favorited &&
                <Dropdown.Item icon='heart' text='Unfavorite' onClick={()=>handleToggleFavorite(false)} />
              }
              <Dropdown.Item icon='share alternate' text='Share'
              onClick={()=>setShareModal(true)}
              disabled={true}
              />
              <Dropdown.Divider />
              {
                canReloadPlaylist &&
                <Dropdown.Item icon='refresh' text='Reload' onClick={props.onReload} />
              }
              <Dropdown.Item icon='download' text='Export' onClick={props.onExport} />
              { post_id &&
                <Dropdown.Item icon='delete' text='Delete'  onClick={props.onDelete} disabled={!props.canSave}/>
              }
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </header>
    </>
  );
}
