import { Icon,Message } from 'semantic-ui-react';

const EmptyPostsMessage = (props) => {
  const title = props.title || 'No items found.';
  return (
    <>
    <Message icon negative>
      <Icon name='warning' />
      <Message.Header>{title}</Message.Header>
      {
        props.message &&
        <p>{props.message}</p>
      }
    </Message>
    </>
  )
}

export default EmptyPostsMessage;
