import React, { useState,useEffect } from "react";
import { Loader } from 'semantic-ui-react';
import {DEBUG} from "../Constants";
import DatabaseAPI from "../services/database/api";
import ApiErrorMessage from "../components/ApiErrorMessage";
import { PlayerProvider } from "../player/Player.Context";
import PlaylistComponent from "../playlist/Playlist";
import Radio from "../radio/Radio";

const SinglePost = (props) => {

  const [apiError,setApiError] = useState();

  const [playlist,setPlaylist] = useState();

  //get the JSON data for this playlist
  const populatePost = async(post_id) => {
    setApiError();
    DatabaseAPI.getPostByID(post_id)
    .then(function(playlist){
      console.log("GOT PLAYLIST YO",playlist);
      setPlaylist(playlist);
    })
    .catch(function(error){
      setApiError(error);
    })

  }

  //init
  useEffect(() => {
    if (props.post){
      setPlaylist(props.post.attributes.jspf);
    }else if (props.post_id!==undefined){
      populatePost(props.post_id);
    }
  }, [props.post,props.post_id]);

  useEffect(() => {
    console.log("PLAYLIST UPDATED",playlist);
  }, [playlist]);

  return (
    <>
    {
      apiError ?
        <ApiErrorMessage error={apiError}/>
      :
      !playlist ?
      <Loader active inline='centered' />
      :
      <PlayerProvider>
        {
          playlist.meta.synced ?
          <Radio
          playlist={playlist}
          action={props.action}
          onUpdate={props.onUpdate}
          />
          :
          <PlaylistComponent
          playlist={playlist}
          action={props.action}
          onUpdate={props.onUpdate}
          />
        }
      </PlayerProvider>
    }
    </>
  );
};

export default SinglePost;
