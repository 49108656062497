import React,{useState,useEffect} from "react";
import { Input,Button,Icon } from "semantic-ui-react";

export const SearchInput = props => {

  //defaults
  //https://blog.logrocket.com/complete-guide-react-default-props
  const {defaultValue = ""} = props;
  const {placeholder = "Search"} = props;
  const {clearButton = true} = props;
  const {submitButton = true} = props;
  const {delay = 0} = props;

  const [value, setValue] = useState(defaultValue);
  const [timer, setTimer] = useState(null);
  const [loading,setLoading] = useState(false);

  const isEmpty = str => {
      return (!str || str.length === 0 );
  }

  const handleChange = e => {
    const str = e.target.value.trim();
    setValue(str);

    if (typeof props.onChange === 'function') {
      if (delay && !isEmpty(str)){
        clearTimeout(timer)
        setLoading(true);
        const newTimer = setTimeout(() => {
          setLoading(false);
          props.onChange(str);
        }, delay)
        setTimer(newTimer);
      }else{
        props.onChange(str);
      }
    }


  }

  const handleClear = e => {
    setValue('');
    clearTimeout(timer)
    setLoading(false);
    if (typeof props.onClear === 'function') {
      props.onClear();
    }
    if (typeof props.onChange === 'function') {
      props.onChange();
    }
  };

  const handleSubmit = e => {
    if (typeof props.onSubmit === 'function') {
      props.onSubmit(value);
    }
  };


  return (
    <Input
    type='text'
    placeholder={placeholder}
    action
    fluid={props.fluid}
    iconPosition={props.icon ? 'left' : undefined}
    >
    {props.icon &&
      <Icon name={props.icon} />
    }

      <input
      name={props.name}
      value={value}
      onChange={handleChange}
      />
      {
        (clearButton && !isEmpty(value)) &&
        <Button disabled={isEmpty(value)} icon='cancel' onClick={handleClear}/>
      }
      {
        submitButton &&
        <Button disabled={(isEmpty(value) || (typeof props.onSubmit !== 'function'))} loading={loading} icon='search' onClick={handleSubmit}/>
      }
    </Input>
  )
}

export default SearchInput;
