import md5 from 'md5';
import _ from 'lodash';
import {Playlist as BackendPlaylist} from 'spiff-utils/build/entities/models';
import Track from  "../track/Track.Model";
import {
  filterIsSinglePropObj
} from "../utils/Utils";

export default class Playlist extends BackendPlaylist{

  constructor(jspf){
    super(jspf);

    //TOUFIX V3
    /*
    By running super(), tracks are initialized? as instances of the Track class (spiff-utils),
    While we want instance of OUR Track class
    Logic should be improved.
    */
    this.track = this.track.map(jspf=> new Track(jspf));
  }

  get hash(){
    return md5(JSON.stringify({...this}));
  }

  get private(){
    const status = this.meta.post_status;
    return (status === 'private');
  }

  set private(bool){
    const status = bool ? 'private' : 'publish';
    this.meta.post_status = status;
  }


  removePrivateMetas(){
    for (const key in this.meta) {
      if (key.startsWith('private/')) {
        delete this.meta[key];
      }
    }
  }



  getTotalTrackCount(){

  	if ( this.meta.synced ){
  		const paginationMeta = this.paginationMeta;
  		const total =  paginationMeta?.total_tracks;
      return (total !== null) ? total : undefined;//TOUFIX TOUCHECK return undefined directly in API ?
  	}else{
  		return this.meta['private/track_count'];
  	}
  }

  getPostUrl(){
    let url;
    const post_name = this.meta.post_name;
    const post_id = this.meta.post_id;

    if (post_id){
      url = `/playlist/${post_id}`;
    }

    if (post_name){
      url = url + `/${post_name}`;
    }

    return url;
  }

  //returns a new playlist that keeps only properties related to a radio.
  //used to know if a radio has been updated (could be saved or not).
  toLiveDTO(){

    const radio = Object.assign(
      new Playlist(),
      {
        title:this.title,
        annotation:this.annotation,
        meta:{
          import_query:this.meta.import_query
        }
      }
    );

    return radio.toDTO();

  }

}
