import React, {useState,useEffect} from 'react';
import { Input,Form } from 'semantic-ui-react';

const TrackEditDetails = (props) => {

  const [jspf,setJspf] = useState({...props.track});

  useEffect(() => {
    if (jspf === props.jspf) return;

    if (typeof props.onUpdateJspf === 'function') {
      props.onUpdateJspf(jspf);
    }

  }, [jspf.duration,jspf.image]);

  return(
    <>
      <Form.Field>
        <label>Duration</label>
        <Input
        type="number"
        defaultValue={jspf.duration}
        onChange={e => setJspf({ ...jspf,duration: e.target.value })}
        />
      </Form.Field>
      <Form.Field>
        <label>Image</label>
        <Input
        defaultValue={jspf.image}
        onChange={e => setJspf({ ...jspf,image: e.target.value })}
        />
      </Form.Field>
    </>
  )
}

export default TrackEditDetails;
