import React, {useState} from 'react';
import { Button, Modal,Segment,Message,Form,Dimmer,Loader,Icon } from 'semantic-ui-react';
import {DEBUG} from "../Constants";
import { useUser } from "./User.Context";
import { useApp } from '../App.Context';
import ApiErrorMessage from "../components/ApiErrorMessage";
import DatabaseAPI from "../services/database/api";

const RegisterModal = (props) => {

  const appContext = useApp();
  const userContext = useUser();
  const [loading,setLoading] = useState(false);
  const [formData,setFormData] = useState({});
  const [error,setError] = useState();

  const handleSetUsername = (event) => {
    setFormData({
      ...formData,
      username:event.target.value
    })
  }

  const handleSetPassword = (event) => {
    setFormData({
      ...formData,
      password:event.target.value
    })
  }

  const handleSetEmail = event => {
    setFormData({
      ...formData,
      email:event.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    DatabaseAPI.register(formData)
    .then(function(response){
      setError();
			userContext.dispatch({ type: 'LOGIN_SUCCESS', payload: response });
			appContext.setLoginModal();
    })
    .catch(function(err){
      setError(err);
    })
    .finally(function(){
      setLoading(false);
    })
  }

  const ModalMessage = () => {

    return(
      <Message icon>
        <Icon name='key' />
        <Message.Content>
          <Message.Header>Connect your SPIFF account !</Message.Header>
          <p>
          Save your favorite tracks, create your own playlist or import them, and much more!
          </p>
        </Message.Content>
      </Message>
    )
  }

  return(
    <Modal
      as={Form}
      onSubmit={handleSubmit}
      autoComplete="off"
      id="loginModal"
      closeIcon
      onClose={()=>{appContext.setLoginModal()}}
      open={(appContext.loginModal === 'register')}
    >
      <Modal.Header>Register</Modal.Header>
      <Modal.Content>
        {
          loading &&
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        }
        <Modal.Description>
            {
              error ?
              <ApiErrorMessage error={error}/>
              :
              <ModalMessage/>
            }
            <Segment>
              <Form.Field>
                <label>Email</label>
                <input
                name="email"
                placeholder='Email'
                onChange={handleSetEmail}
                />
              </Form.Field>
              <Form.Field>
                <label>Username</label>
                <input
                name="username"
                placeholder='Username'
                onChange={handleSetUsername}
                />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <input
                name="password"
                type='password'
                placeholder='Password'
                onChange={handleSetPassword}
                />
              </Form.Field>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={props.onCancel}>Cancel</Button>
        <Button primary type="submit">Register</Button>
      </Modal.Actions>
    </Modal>
  )

}

export default RegisterModal
