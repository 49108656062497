//for google analytics
import React from 'react'
import {useLocation} from "react-router-dom";
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const GATracker = (props) => {

    let location = useLocation();

    //send to Google Analytics when location changes
    React.useEffect(() => {
      //console.log("UPDATE GA",location.pathname);
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }, [location]);

    return <div></div>;
};

export default GATracker;
