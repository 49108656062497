import React, { useState,useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";

import {STAFF_URL} from "../../Constants";

import MusicPosts from "../music/posts";
import UserTracks from "../user/UserTracks";

export const MusicRoutes = (props) => {
  let navigate = useNavigate();
  let { username,type,page } = useParams();

  return (
    <Routes>
      <Route path="user/:userid/:username">
        <Route
        path="tracks/*"
        element={<UserTracks/>}
        />
        <Route
        path="playlists/*"
        element={<MusicPosts/>}
        />
      </Route>
      <Route exact path='staff/playlists' element={<Navigate to={`music${STAFF_URL}/playlists/favorited`} />} />
    </Routes>
  )
}

export default MusicRoutes;
