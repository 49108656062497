import React, { useState } from "react";
import AdvancedWizardStep,{getUISelector} from "./AdvancedWizardStep";

const StepData = (props) => {

  const debugData = undefined;

  const [onChangeErrors,setOnChangeErrors] = useState();//TOUFIX what on init ?


  const handleOnChange = ({ errors, data }) => {
    setOnChangeErrors(errors);
  }

  const uischema = {
    type: 'VerticalLayout',
    elements: [
      getUISelector('#/properties/selectors/properties/playlist_title',onChangeErrors),
      getUISelector('#/properties/selectors/properties/playlist_author',onChangeErrors),
      getUISelector('#/properties/selectors/properties/playlist_image',onChangeErrors),
      getUISelector('#/properties/selectors/properties/playlist_description',onChangeErrors),
      getUISelector('#/properties/selectors/properties/track_title',onChangeErrors),
      getUISelector('#/properties/selectors/properties/track_artist',onChangeErrors),
      getUISelector('#/properties/selectors/properties/track_album',onChangeErrors),
      getUISelector('#/properties/selectors/properties/track_image',onChangeErrors),

      getUISelector('#/properties/selectors/properties/track_duration',onChangeErrors),
      getUISelector('#/properties/selectors/properties/track_location',onChangeErrors),
      getUISelector('#/properties/selectors/properties/track_link',onChangeErrors),
      getUISelector('#/properties/selectors/properties/track_identifier',onChangeErrors)
    ]
  };

  console.log("TRACKS SCHEMA",props.schema);

  return (
    <AdvancedWizardStep
    id="advanced-importer-track"
    schema={props.schema}
    uischema={uischema}
    data={props.data}
    debugData={debugData}
    onChange={handleOnChange}
    onSubmit={props.onSubmit}
    />
  );
};

export default StepData;
