/*index.jsx*/
import React, {useEffect,useState} from "react";
import _ from 'lodash';
import {
  useNavigate,
  useLocation,
  matchRoutes,
  Link
} from "react-router-dom";
import { Form,Button,Checkbox,Container,Message,Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import {DEBUG} from "../../../Constants";
import { getToastErrorMessage } from "../../../utils/Utils";

import DatabaseAPI from "../../../services/database/api";
import LastFmAPI from "../../../services/lastfm";
import { useUser } from "../../../user/User.Context";
import classNames from "classnames";

const LastfmSettingsSection = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const userContext = useUser();

  //data that we can load / update
  const filterProfileData = profile => {
    return _.pick(profile,['lastfm_session','lastfm_scrobbler','lastfm_favorite']);
  }

  const profile = userContext.user.profile;
  const isConnected = profile?.lastfm_enabled;

  const data = filterProfileData(profile);
  const [newData,setNewData] = useState(data);

  const [lastFmProfile,setLastFmProfile] = useState();
  const [isLoading,setIsLoading] = useState(false);

  const handleSetLastfmScrobblerEnabled = (event) => {
    setNewData({
      ...newData,
      lastfm_scrobbler:!newData.lastfm_scrobbler
    })
  }

  const handleSetLastfmSyncFavoritesEnabled = (event) => {
    setNewData({
      ...newData,
      lastfm_favorite:!newData.lastfm_favorite
    })
  }

  //check for callback URL token and get user session key
  useEffect(()=>{

    //check if this is the LastFM API callback
    const getUrlToken = () => {

      const routeMatch = matchRoutes([{path:'/user/:userid/:username/settings/lastfm'}], location);
      if (!routeMatch) return;

      //get lastFm token
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get('token');
    }

    const token = getUrlToken();

    if (token){
      LastFmAPI.getSessionKey(token)
      .then(function(resp){

        const payload = {
          ...newData,
          lastfm_username:resp.name,
          lastfm_session:resp.key
        };

        return updateData(payload);
      })
      .then(function(newData){
        //change URL so token is not checked a second time (would return an error from Last.fm )
        const successUrl = props.location.pathname;
        navigate(successUrl);
      })
      .catch((error) => {
        toast.error(getToastErrorMessage(error,'We have not been able to get your Last.fm session token.'));
        //TOUFIX TODO disconnect last.fm ?
      });
    }

  },[location])

  //make sure user is connected by testing the Last.fm API
  useEffect(()=>{
    if (!isConnected){
      setLastFmProfile();
    }else{
      setIsLoading(true);
      LastFmAPI.getMyProfile()
      .then(function(resp){
        setLastFmProfile(resp);
      })
      .catch(function(error){
        toast.error(getToastErrorMessage(error,'Error getting your Last.fm profile data.'));
      })
      .finally(function(){
        setIsLoading(false);
      })
    }

  },[isConnected])

  const updateData = async(data) => {

    setIsLoading(true);
    return userContext.updateProfile(data)
    .then(function(resp){
      return resp;
    })
    .then(function(profile){
      let newData = filterProfileData(profile);
      setNewData(newData);
      toast.success("Last.fm settings successfully updated.");
      return newData;
    })
    .catch(function(error){
      toast.error(getToastErrorMessage(error,'Error updating Last.fm settings'));
    })
    .finally(function(){
      setIsLoading(false);
    })
  }

  const handleSubmitForm = async() => {
    updateData(newData);
  }

  const AuthButton = () => {

    const handleDisconnect = async(e) => {
      e.preventDefault();
      setLastFmProfile();
      updateData({lastfm_session:null});
    }


    const authUrl = `http://www.last.fm/api/auth/?api_key=${process.env.REACT_APP_LASTFM_API_KEY}`;

    return(
      <>
      {
        !isConnected ?
        <Link to={authUrl} target="_blank" rel="noopener noreferrer"><Button>Connect</Button></Link>
        :
        <Button onClick={handleDisconnect}>Disconnect</Button>
      }
      </>
    )
  }

  const LastFmMessage = () => {

    return(
      <Message
      className={classNames({
        'loading': isLoading,
        'freeze': isLoading
      })}
      icon
      >
        <Icon name='lastfm' />
        {
          lastFmProfile ?
          <Message.Content>
            <Message.Header>Connected to Last.fm as <em>{lastFmProfile?.name}</em></Message.Header>
          </Message.Content>
          :
          <Message.Content>
            <Message.Header>Connect your Last.fm account !</Message.Header>
            <p>We can scrobble the tracks you listen to on your last.fm account, and much more!</p>
          </Message.Content>
        }
      </Message>
    )
  }

  return (

    <Container id="settings-lastfm">
      <h3>Last.fm <AuthButton/></h3>
      <LastFmMessage/>
      <Form
      onSubmit={handleSubmitForm}
      className={classNames({
        'loading': isLoading,
        'freeze': !isConnected
      })}
      >
        <Form.Field>
          <Checkbox
          toggle
          label='Enable scrobbler'
          defaultChecked={data?.lastfm_scrobbler}
          onChange={handleSetLastfmScrobblerEnabled}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
          toggle
          label='Sync with Last.fm when favoriting or unfavoriting a track'
          defaultChecked={data?.lastfm_favorite}
          onChange={handleSetLastfmSyncFavoritesEnabled}
          />
        </Form.Field>
        <Button type='submit'>Save</Button>
      </Form>
    </Container>
  );
};

export default LastfmSettingsSection;
