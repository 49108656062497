import React, { useState,useEffect } from "react";
import { Button,Icon,Popup} from 'semantic-ui-react';

const WizardSubmitButton = (props) => {

  const lyrics = [
    {
      'artist':"Queen",
      'track':"A Kind of Magic",
      'lyric':"It's a kind of magic"
    },
    {
      'artist':"Sugababes",
      'track':'Push the button',
      'lyric':"Push the button, push the button"
    },
    {
      'artist':"The Doors",
      'track':'Touch Me',
      'lyric':"Now touch me, babe"
    },
    {
      'artist':"Bob Marley & The Wailers",
      'track':'Rock It Baby',
      'lyric':"Rock it, baby; rock it, baby, tonight"
    },
    {
      'artist':"Adele",
      'track':'Rolling in the Deep',
      'lyric':"There's a fire starting in my heart"
    }
  ];

  const [currentLyric, setCurrentLyric] = useState(lyrics[0]);

  const refreshLyrics = () => {
    const lyric = lyrics[Math.floor(Math.random() * lyrics.length)];
    setCurrentLyric(lyric);
  }

  useEffect(() => {
    refreshLyrics(); //children function of interest
  },[]);

  return (
    <>
      <Button
      primary
      onClick={props.onClick}
      disabled={props.disabled}
      >
      {
        props.loading &&
        <Icon loading name="circle notch"/>
      }
      {currentLyric.lyric}
      </Button>
      <Popup content={'in "'+currentLyric.track+'" by '+currentLyric.artist} trigger={<Icon className="clickable" name='question circle'/>} />
    </>
  );
}

export default WizardSubmitButton;
