import React, {useState} from 'react';
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import ForgottenPasswordModal from "./ForgottenPasswordModal";
import { useApp } from '../App.Context';

const AuthUserModals = props => {
  const appContext = useApp();

  const handleCancel = e => {
    e.preventDefault();
    appContext.setLoginModal('')
  }

  switch (appContext.loginModal) {
    case 'login':
      return <LoginModal onCancel={handleCancel}/>;
    case 'register':
      return <RegisterModal onCancel={handleCancel}/>;
      case 'forgottenPassword':
        return <ForgottenPasswordModal onCancel={handleCancel}/>;
    default:
      return null;
  }

}

export default AuthUserModals
