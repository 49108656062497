import React, { useReducer, createContext } from "react";

export const PlayerContext = createContext();

const initialState = {
  media_progress:undefined,
  //controls
  volume:0.8,
  muted:false,
  looping:(localStorage.getItem("looping") !== null) ? JSON.parse(localStorage.getItem('looping')) : true,
  widget: (localStorage.getItem("show_embed") !== null) ? JSON.parse(localStorage.getItem('show_embed')) : true //JSON.parse to cast to bool
}

const reducer = (state, action) => {

  if (action.type!=='SET_MEDIA_PROGRESS'){
    console.log("player reducer, action:"+action.type,action.payload);
  }

  switch(action.type) {

    case "SET_MEDIA_PROGRESS":

      return {
          ...state,
        media_progress:action.payload
      }
    break;

    case "TOGGLE_MUTED":

      var bool = (action.payload !== undefined) ? action.payload : !state.muted;

      return {
          ...state,
        muted:bool
      }
    break;

    case "TOGGLE_WIDGET":

      var bool = (action.payload !== undefined) ? action.payload : !state.widget;
      localStorage.setItem('show_embed',bool);

      return {
          ...state,
        widget:bool
      }
    break;


    case "TOGGLE_LOOPING":

      var bool = (action.payload !== undefined) ? action.payload : !state.looping;
      localStorage.setItem('looping',bool);

      return {
          ...state,
        looping:bool
      }
    break;

    case "SET_VOLUME":

      return {
          ...state,
        volume:parseFloat(action.payload)
      }
    break;

    default:
      throw new Error();
  }
};

export const PlayerProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PlayerContext.Provider value={[state, dispatch]}>
      {props.children}
    </PlayerContext.Provider>
  );
};
