import React, {useState,useEffect} from 'react';
import { Input,Form,TextArea,Radio } from 'semantic-ui-react';
import Playlist from  "../Playlist.Model";
import {isEqualPlaylists} from "../../utils/Utils";

const PlaylistEditSettings = (props) => {
  const playlist = props.playlist;
  const [newPlaylist,setNewPlaylist] = useState(props.playlist);

  useEffect(() => {

    console.log("OLD",playlist);
    console.log("ENEW",newPlaylist);

    if (isEqualPlaylists(playlist,newPlaylist)) return;

    if (typeof props.onChange === 'function') {
      props.onChange(newPlaylist);
    }
  }, [newPlaylist.private]);

  const updatePrivate = (bool) => {
    setNewPlaylist(Object.assign(
      new Playlist(),
      newPlaylist,
      {
        private:bool
      }
    ));
  }

  return(
    <>
      <Form.Field>
        <label>Private</label>
        <Radio
        toggle
        checked={newPlaylist.private}
        onChange={(e)=>updatePrivate(!playlist.private)}
        />
      </Form.Field>
    </>
  )
}

export default PlaylistEditSettings;
