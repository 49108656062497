import React from "react";
import {DEBUG} from "../Constants";
import { LastLocationPath } from "../utils/LastLocationPath";
import SinglePost from "../components/SinglePost";
import {
  useParams
} from "react-router-dom";

const PostPage = (props) => {

  let { post_id,action } = useParams();

  const handleUpdate = (playlist,updateCount) => {
    if(updateCount===1){//on first load
      DEBUG && console.info("Post initialized successfully!",playlist);
      const postLink = playlist.getPostUrl();
      if (postLink){
        LastLocationPath.set(postLink);
      }
    }else{
      DEBUG && console.info("Post updated successfully!",playlist);
    }
  }

  return (
    <SinglePost
    post_id={post_id}
    action={action}
    onUpdate={handleUpdate}
    />
  );
};

export default PostPage;
