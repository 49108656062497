import React from "react";
import { Icon } from 'semantic-ui-react';

const PostCover = (props) => {

  const imageUrl = props.image

  const imageAttr = imageUrl ? `url('${imageUrl}')` : '';

  return (
    <>
      {
        props.selected &&
        <div className="playlist-selected">
          <Icon name='check circle'/>
        </div>
      }
        <div className="cover-img" style={{backgroundImage: imageAttr}}></div>
    </>
  )
}

export default PostCover;
