import React,{useState,useEffect} from "react";
import {
  Route,
  Navigate,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import { Container } from 'semantic-ui-react';
import { ToastContainer,Slide } from 'react-toastify';
import { Grid } from 'semantic-ui-react';

import { LastLocationPath } from "./utils/LastLocationPath";
import { useUser } from "./user/User.Context";
import AuthUserModals from "./user/AuthUserModals";

import "./App.scss";
import GATracker from "./components/GATracker";
import HomePage from "./pages/home";
import PostPage from "./pages/post";
import MusicRoutes from "./pages/music/index";
import UserRoutes from "./pages/user/index";
import WizardRoutes from "./pages/wizard/index";
import AdvancedWizard from "./wizard/advanced/AdvancedWizard";
import NotFoundPage from "./pages/404";

import { TopMenu } from "./pages/menu.top";
import MainMenu from "./pages/menu.main";
import UserMenu from "./pages/menu.user";

import {getUserLink} from "./utils/Utils";

const AppContent = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const userContext = useUser();
  const userProfile = userContext.user.profile;
  const userBaseLink = getUserLink(userProfile?.id,userProfile?.username);

  const getLastFmToken = () => {
    return new URLSearchParams(location.search).get('token');
  }

  const getSpotifyAuthorizationCode = () => {
    return new URLSearchParams(location.search).get('code');
  }

  const lastfmToken = getLastFmToken();
  const lastfmAuthUrl = (lastfmToken && userBaseLink) ? `${userBaseLink}/settings/lastfm?token=${lastfmToken}` : undefined;

  const spotifyAuthorizationCode = getSpotifyAuthorizationCode();
  const spotifyAuthUrl = (spotifyAuthorizationCode && userBaseLink) ? `${userBaseLink}/settings/?spotifyCode=${spotifyAuthorizationCode}` : undefined;

  //init location
  useEffect(() => {
    //redirect to last viewed path
    if (location.pathname !== '/') return; //only when on home
    const lastLocationPath = LastLocationPath.get();
    if (lastLocationPath){
      console.log("app init - redirect to last stored path",lastLocationPath);
      navigate(lastLocationPath);
    }
  }, []);

  return (
    //https://dev.to/nilanth/how-to-create-public-and-private-routes-using-react-router-72m
    <>
      <GATracker />
      <ToastContainer
      position="bottom-right"
      transition={Slide}
      autoClose={5000}
      hideProgressBar={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
      />
      <AuthUserModals/>
      <TopMenu/>
        <Grid className='page-container'>
          <Grid.Column width={3} className='page-menu'>
            <Routes>
              <Route
              path="/user/:userid/:username/*"
              element={<UserMenu/>}
              />
              <Route
              path="*"
              element={<MainMenu/>}
              />
            </Routes>
          </Grid.Column>
          <Grid.Column width={13} className='page-content'>
            <Routes>
              {
                /*redirect from Spotify callback URL*/
                spotifyAuthUrl &&
                <Route
                path='/spotify/auth'
                element={<Navigate to={spotifyAuthUrl} replace />}
                />
              }
              {
                /*redirect from Last.fm callback URL*/
                lastfmAuthUrl &&
                <Route
                path='/lastfm-user-token' //TOUFIX we should update this to /lastfm/auth
                element={<Navigate to={lastfmAuthUrl} replace />}
                />
              }
              <Route
              path="/"
              element={<HomePage/>}
              />
              <Route path="/music/*"
              element={<MusicRoutes/>}
              />

              <Route
              path="/playlist/:post_id/*"
              element={<PostPage/>}
              />
              <Route
              path="/playlist/:post_id/json/*"
              element={<AdvancedWizard/>}
              />
              <Route
              path="/user/:userid/:username/*"
              element={<UserRoutes/>}
              />
              <Route
              path="/wizard/*"
              element={<WizardRoutes/>}
              />
              <Route
              path="*"
              element={<NotFoundPage/>}
              />
           </Routes>
          </Grid.Column>
        </Grid>
    </>
  );
}

export default AppContent;
