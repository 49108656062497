import {DEBUG} from "../../Constants";

export class ImportApiCache {

  static TOKEN_KEY = 'import_token';

  static setToken(token: string): void {
    DEBUG && console.info("Store import API token",token);
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  static getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  static clear(): void {
    DEBUG && console.info("Clear import API cache");
    localStorage.removeItem(this.TOKEN_KEY);
  }
}
