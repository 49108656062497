/*index.jsx*/
import React from "react";
import { Container } from "semantic-ui-react";
import { Navigate } from 'react-router-dom';
import LastfmSettingsSection from "./lastfm";
import SpotifySettingsSection from "./spotify";
import ProvidersSettingsSection from "./providers";


const UserSettingsPage = (props) => {
  return (
    <Container id="user-settings">
      <ProvidersSettingsSection/>
      <LastfmSettingsSection/>
      <SpotifySettingsSection/>
    </Container>
  );
};

export default UserSettingsPage;
