import React from "react";
import { Message,Icon } from "semantic-ui-react";

const ErrorMessage = (props) => {

  const icon = props.icon || 'warning';
  const hasIcon = (icon !== undefined);
  const title = props.title || 'Error';
  const content = props.content;
  return (
    <Message icon={hasIcon}>
      <Icon name='warning' />
      <Message.Content>
        <Message.Header>{title}</Message.Header>
        {
          content &&
          <p>
          {content}
          </p>
        }
      </Message.Content>
    </Message>
  )
};

export default ErrorMessage;
