import React from "react";
import {DEBUG} from "../Constants";
import {databaseAPIclient} from "./database/connect";

class SpotifyAPI  extends React.Component {
    static async getMyProfile(){
      const config = {
        method: 'get',
        url: '/spiff/spotify/me/profile',
        withCredentials:true
      }
      return databaseAPIclient.request(config)
      .then(resp => resp.data?.body)
    }
}

export default SpotifyAPI;
