import React, {useState,useEffect} from 'react';
import { Message,TextArea,Form } from 'semantic-ui-react';
import Track from "../Track.Model";

const TrackEditLinks = props => {
  const trackObj = props.track;
  const [textarea,setTextarea] = useState('');

  const textarea_urls = trackObj.meta['private/edited_urls'];
  const edit_urls = textarea_urls || trackObj.allUrls;

  useEffect(() => {
    const textarea = edit_urls.join("\n");
    setTextarea(textarea);
  },[edit_urls]);

  const handleChange = (event) => {
    const textarea = event.target.value;
    const urls = textarea.split("\n");
    const newTrackObj = new Track({...trackObj});

    //if we use Track.addUrls() here, it will add a new entry each time we type a character.
    //so use a specific meta that we'll delete upstream when confirming the track edition.
    newTrackObj.meta['private/edited_urls']=urls;

    if (typeof props.onUpdateJspf === 'function') {
      props.onUpdateJspf(newTrackObj);
    }

  }

  return(
    <>
    <Message>One link per line.</Message>
      <Form.Field>
        <TextArea
        defaultValue={textarea}
        onChange={handleChange}
        rows={8}
        />
      </Form.Field>
    </>
  )
}

export default TrackEditLinks;
