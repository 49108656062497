import React from "react";
import {DEBUG} from "../Constants";
import {databaseAPIclient} from "./database/connect";

class LastFmAPI  extends React.Component {

    static canScrobbleTrack(track){
      if ( !track.creator ) throw new Error('Missing track artist.');
      if ( !track.title ) throw new Error('Missing track title.');
      return true;
    }

    //TOUFIX SHOULD BE FIRE BEFORE THE END OF THE SONG.
    static async scrobbleTrack(track){

      try{
        this.canScrobbleTrack(track);
      } catch (error) {
  			Promise.reject("cannot scrobble track: " + error);
  		}

      const config = {
       method: 'post',
       url: '/spiff/lastfm/track/scrobble',
       withCredentials:true,
       data: {
         track: {...track}
       }
      }

      DEBUG && console.log("Last.Fm scrobble track",config);

      return databaseAPIclient.request(config)
    }

    static async toggleFavoriteTrack(track,bool){

      if (bool === undefined){
        throw("Missing 'bool' parameter.");
      }

      try{
        this.canScrobbleTrack(track);
      } catch (error) {
        if (bool){
          Promise.reject("cannot favorite track on Last.Fm: " + error);
        }else{
          Promise.reject("cannot unfavorite track on Last.Fm: " + error);
        }
  		}

      const config = {
       method: 'post',
       url: bool ? '/spiff/lastfm/track/favorite' : '/spiff/lastfm/track/unfavorite',
       withCredentials:true,
       data: {
         track: {...track}
       }
      }

      DEBUG && console.log("Last.Fm toggle favorite track",config);

      return databaseAPIclient.request(config)
    }

    static async getSessionKey(token){
      const config = {
        method: 'get',
        url: '/spiff/lastfm/session',
        withCredentials:false,
        params: {
          token:  token
        }
      }
      return databaseAPIclient.request(config)
      .then(resp => resp.data)
    }

    static async getMyProfile(){
      const config = {
        method: 'get',
        url: '/spiff/lastfm/me/profile',
        withCredentials:true
      }
      return databaseAPIclient.request(config)
      .then(resp => resp.data?.user)
    }
}

export default LastFmAPI;
