////https://gist.github.com/jimode/c1d2d4c1ab33ba1b7be8be8c50d64555

import React, { useState,useEffect,createContext } from 'react';
import {APP_VERSION} from "./Constants";
import ImportAPI from "./services/import/api";
import DatabaseAPI from "./services/database/api";
import { ImportApiCache } from "./services/import/cache";
import { DatabaseApiCache } from "./services/database/cache";

const AppContext = createContext();

export function AppProvider({children}){

//TOUFIX V3 check how user context is populated (local data, etc.)

	const [loginModal,setLoginModal] = useState(undefined);
	const [loadingImporters,setLoadingImporters] = useState(false);
	const [loadingTags,setLoadingTags] = useState(false);
	const [importers,setImporters] = useState(undefined);//undefined until API call
	const [tags,setTags] = useState([]);

	//maybe clear local data
  useEffect(() => {
    const localVersion = DatabaseApiCache.getAppVersion();

    console.info('App version',localVersion);

    if ( APP_VERSION !== localVersion ){
      console.info('Clear old app cache',localVersion);
      ImportApiCache.clear();
      DatabaseApiCache.clear();
      DatabaseApiCache.setAppVersion();
    }
  }, []);

	//load importers on init
  useEffect(() => {
		setLoadingImporters(true);
    ImportAPI.getImporters()
    .then(function(importers){
      setImporters(importers);
    })
		.catch(error => {
			console.error("Unable to load APP importers.");
		})
		.finally(function(){
			setLoadingImporters(false);
		})
  }, []);

	//load tags on init
  useEffect(() => {
		setLoadingTags(true);
    DatabaseAPI.getTags()
    .then(function(tags){
			setTags(tags);
    })
		.catch(error => {
			console.error("Unable to load APP tags.");
		})
		.finally(function(){
			setLoadingTags(false);
		})
  }, []);

	//get the importer that matches an URL
	const getImporterByUrl = url => {
    return (importers || []).find(function(importer){
      return importer.infos.patterns.find(function(item){
        const regex = new RegExp(item.regex);
				return regex.test(url);
      })
    })
  }

	//get tag objects from an array of names
	const getTagsFromNames = names => {
		return (tags || []).filter(function(tag){
      return names.includes(tag.name);
    })
  }

	// NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {
		loginModal,
		setLoginModal,
		importers,
		getImporterByUrl,
		tags,
		getTagsFromNames,
		loadingImporters
	};

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export function useApp() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a AppProvider')
  }
  return context
}
