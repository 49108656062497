import React from "react";
import {getUrlDomain,shortUrl} from "../utils/Utils";

const ShortLink = (props) => {
  const url = props.url.replace(/\/+$/, '');//trailing slash
  const domain = getUrlDomain(url);
  const displayUrl = shortUrl(url,props.length);

  //if component has an onClick attr, use it.
  const handleClick = (e) => {
    if (typeof props.onClick === 'function') {
      e.preventDefault();
      props.onClick(e);
    }
  }

  return (
    <a
    className={'shortlink ' + (props.className || '')}
    href={url}
    onClick={handleClick}
    target="_blank"
    rel="noopener noreferrer"
    data-domain={domain}
    title={url}>
      {displayUrl}
    </a>
  );
}

export default ShortLink;
