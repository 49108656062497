import React, { useState,useEffect,useRef } from "react";
import {DEBUG} from "../Constants";
import DatabaseAPI from "../services/database/api";
import PostQueryFilters from "./PostQueryFilters";
import PostList from "../playlist/manager/PostList";
import {useNavigate} from "react-router-dom";
import { Loader,Dimmer } from 'semantic-ui-react';
import ApiErrorMessage from "../components/ApiErrorMessage";

const PostQuery = (props) => {

  let history = useNavigate();

  const [error,setError] = useState();
  const [posts,setPosts] = useState();
  const [loading,setLoading] = useState();

  //get posts
  useEffect(() => {

    if (props.query !== undefined){
      setLoading(true);
      DatabaseAPI.getPosts(props.query)
      .then(function(posts){
        setPosts(posts);
      })
      .catch(function(error){
        setError(error);
      })
      .finally(function(){
        setLoading(false);
      })
    }
  }, [props.query]);

  return(
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <PostQueryFilters query={props.query}/>
      {
        error ?
          <ApiErrorMessage error={error}/>
        :
        <PostList
        items={posts}
        selectedItems={props.selectedPosts}
        onNewItem={props.onNewItem}
        onItemClick={props.onItemClick}
        onSearchFilter={props.onSearchFilter}
        noItems={props.noItems}
        layout={props.layout}
        />
      }
    </>
  )
}

export default PostQuery;
